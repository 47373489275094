import { Badge } from "@/components/Badge";
import { ArrayElement, BreakPoints } from "@/types";
import { DateTime } from "luxon";
import { useBreakPoint } from "@/utils/hooks";
import { PreviousTestsActionRow } from "@/components/PreviousTestsActionRow";
import { titlize } from "@/utils/common/titlize";
import { DashboardTable } from "@/components/DashboardTable";
import { TableRowContentMapper } from "@/types";
import { DashboardLink } from "@/components/DashboardLink";
import { PreviousTestsPageTestName } from "../PreviousTestsPageTestName";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { WithTooltip } from "@/components/pages/dashboard";
import TestScoreBadge from "@/components/TestScoreBadge";
import TestSubjects from "./test-subjects";
import TestSystems from "./test-systems";
import row from "./row";
import { GetPreviousTestsOutput } from "@/api/src/server/routers";

interface PreviousTestsTableProps {
  qBankEndpoint: string;
  onTestRenameClick: (testId: number) => void;
  tests: GetPreviousTestsOutput['tests'];
  isLoading: boolean;
}

const PreviousTestsTable = ({
  qBankEndpoint,
  onTestRenameClick,
  tests,
  isLoading,
}: PreviousTestsTableProps) => {
  const breakPoint = useBreakPoint();

  const mapColumnContent: TableRowContentMapper<ArrayElement<GetPreviousTestsOutput['tests']>> = (
    column,
    content,
  ) => {
    const startedAt =
      column.name === "Date" &&
      DateTime.fromJSDate(new Date((content as Date) || 0));

    switch (column.name) {
      case "Score": {
        if (!content.submitted && content.isBeingEvaluated) {
          return (
            <Badge className="lg:w-12 lg:h-12 md:w-10 md:h-10 p-0">
              <WithTooltip
                tooltip="This test is currently being evaluated."
                className="z-[999]"
              >
                <LoadingSpinner />
              </WithTooltip>
            </Badge>
          );
        }
        if (!content.submitted) {
          return (
            <DashboardLink href={`/tests/${content.testId}`}>
              Resume
            </DashboardLink>
          );
        }

        return (
          <TestScoreBadge
            score={content.score}
            isTestSubmitted={content.submitted}
          />
        );
      }
      case "Name":
        return (
          <PreviousTestsPageTestName
            testName={content.name}
            testId={content.id}
            onRenameButtonClick={onTestRenameClick}
          />
        );
      case "Date":
        return (
          <div className="flex flex-col md:flex-row justify-start items-center gap-0 md:gap-1  text-center md:text-left">
            <p>{(startedAt as DateTime).toFormat("dd ")}</p>
            <p>
              {(startedAt as DateTime).toFormat(
                breakPoint < BreakPoints.Medium
                  ? "MM,"
                  : breakPoint < BreakPoints.Large
                    ? "LLL,"
                    : "MMMM,",
              )}
            </p>
            <p>{(startedAt as DateTime).toFormat("yyyy")}</p>
          </div>
        );
      case "Mode":
        return (
          <>
            {(content as string).split(",").includes("tutor")
              ? "Tutor"
              : "Untutored"}
            {(content as string).split(",").includes("timed") ? ", Timed" : ""}
          </>
        );
      case "Q. Pool":
        return (
          <div className="flex flex-col justify-start items-start">
            {(content as string).split(",").map((p, index, array) => (
              <p key={index}>
                {titlize(p)}
                {index === array.length - 1 ? "" : ","}
              </p>
            ))}
          </div>
        );
      case "Subjects":
        return (
          <TestSubjects
            subjects={content}
          />
        );
      case "Systems":
        return (
          <TestSystems
            systems={content}
          />
        );
      case "# Qs":
        return <>{content}</>;
      case "Actions":
        return (
          <PreviousTestsActionRow
            questionIds={content.questions}
            testName={content.name as string}
            testId={content.id as number}
            qBankEndpoint={qBankEndpoint}
            isTestSubmitted={content.submitted}
          />
        );
    }
  };

  return (
    <DashboardTable
      allowMobileTable={false}
      columns={row}
      columnContentMapper={mapColumnContent}
      isLoading={isLoading}
      items={tests}
    />
  );
};

export default PreviousTestsTable;
