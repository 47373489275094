import { trpc } from "@/utils/trpc";
import { useParams } from "@tanstack/react-router";

const useTestPageTestSession = (opts?: { enabled?: boolean }) => {
  const { enabled = false } = opts ?? {};
  const { testId } = useParams({ from: "/tests/$testId" });
  return trpc.test.getTestSession.useQuery(
    { testId: Number(testId) },
    { enabled },
  );
};

export default useTestPageTestSession;
