import { createFileRoute } from "@tanstack/react-router";
import { DashboardFrame } from "@/components/DashboardFrame";
import { PreviousTestsColumnSelect } from "@/components/PreviousTestsColumnSelect";
import PreviousTestsMobile from "./-components/previous-tests-mobile-table";
import PreviousTestsTable from "./-components/previous-tests-table";
import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import { useEffect, useMemo, useState } from "react";
import { PreviousTestsPagePaginator } from "./-components/PreviousTestsPagePaginator";
import { PreviousTestsPageTestRenameDialog } from "./-components/PreviousTestsPageTestRenameDialog";
import { PreviousTestsPageQueryInput } from "./-components/PreviousTestsPageQueryInput";
import useRenameTest from "@/hooks/test-hooks/use-rename-test";
import {
  usePreviousTestsPageCurrentPage,
  usePreviousTestsPageSearch,
  usePreviousTestsPageTestsPerPage,
} from "@/utils/stores/previousTestsPageStore";
import { useOnMobile } from "@/hooks";
import { useTitle } from "@/hooks/common/use-title";
import useDashboardQuestionBank from "../-dashboard-hooks/use-dashboard-question-bank";
import usePreviousTests from "@/hooks/test-hooks/use-previous-tests";
import _ from "underscore";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/previous-tests",
)({
  component: TestsPage,
});

function TestsPage() {
  const params = Route.useParams();
  const { data: questionBank } = useDashboardQuestionBank();
  useTitle(`Previous Tests ${questionBank?.name}`);
  const [renameTestDialogVisible, setRenameTestDialogVisible] =
    useState<boolean>(false);

  const { setHeaderText } = useDashboardLayoutStore();


  const isOnMobile = useOnMobile();

  const [currentPage, setCurrentPage] = usePreviousTestsPageCurrentPage();
  const [testsPerPage, setTestsPerPage] = usePreviousTestsPageTestsPerPage();
  const [search] = usePreviousTestsPageSearch();
  const { data: testResponse, isLoading, isPlaceholderData } = usePreviousTests({ qBankId: questionBank?.id ?? '', page: currentPage, testsPerPage, search });

  const { mutate: renameTest } = useRenameTest({ page: currentPage, testsPerPage, qBankId: questionBank?.id ?? '', search });

  const tests = useMemo(() => _.sortBy(
    testResponse?.tests.map(
      t => ({
        ...t,
        startedAt: new Date(t.startedAt)
      })) ?? [], t => t.startedAt).reverse(),
    [testResponse]);

  useEffect(() => {
    setHeaderText("Previous Tests");
    //
    //   if (error) {
    //     toast.error(
    //       `Could not retrieve previous tests, an error occured: ${error.message}`,
    //     );
    //   }
    //
    //   setTestsLoading(isLoading);
    return () => setHeaderText("");
  }, [
    // data, isLoading, error, loadTests,
    setHeaderText,
  ]);

  const [testToRenameId, setTestToRenameId] = useState<number | null>(null);
  useEffect(() => {
    if (testToRenameId) return setRenameTestDialogVisible(true);
  }, [testToRenameId, setRenameTestDialogVisible]);

  const handleTestRename = (newName: string) => {
    if (!testToRenameId) return;
    renameTest({ testId: testToRenameId, name: newName });
    setRenameTestDialogVisible(false);
    setTestToRenameId(null);
  };

  return (
    <DashboardFrame className="min-h-full pb-5 h-full">
      <PreviousTestsPageTestRenameDialog
        open={renameTestDialogVisible}
        onOpenChange={setRenameTestDialogVisible}
        onTestNameChange={handleTestRename}
        testName={
          testToRenameId
            ? (tests?.find((t) => t.id === testToRenameId)?.name ?? "")
            : ""
        }
      />
      <div className="hidden md:flex flex-row justify-between items-center w-full p-3">
        <PreviousTestsColumnSelect />
        <div className="flex flex-col justify-start items-start gap-5">
          <PreviousTestsPageQueryInput isLoading={isLoading || isPlaceholderData} />
          <PreviousTestsPagePaginator
            currentPage={currentPage}
            onItemsPerPageChange={setTestsPerPage}
            onCurrentPageChange={setCurrentPage}
            numberOfItems={testResponse?.testCount ?? 0}
            itemsPerPage={testsPerPage}
          />
        </div>
      </div>
      <div className="md:hidden flex flex-col justify-start items-center w-full p-3 gap-3">
        <div className="self-start">
          <PreviousTestsColumnSelect />
        </div>
        <div className="self-end">
          <PreviousTestsPageQueryInput isLoading={isLoading || isPlaceholderData} />
        </div>
        <div className="self-center">
          <PreviousTestsPagePaginator
            currentPage={currentPage}
            onItemsPerPageChange={setTestsPerPage}
            onCurrentPageChange={setCurrentPage}
            numberOfItems={testResponse?.testCount ?? 0}
            itemsPerPage={testsPerPage}
          />
        </div>
      </div>
      {!isOnMobile && (
        <div className="w-full hidden md:block">
          <PreviousTestsTable
            tests={tests ?? []}
            isLoading={isLoading || isPlaceholderData}
            onTestRenameClick={(testId) => setTestToRenameId(testId)}
            qBankEndpoint={params.qbankEndpoint}
          />
        </div>
      )}
      {isOnMobile && (
        <div className="w-full block md:hidden">
          <PreviousTestsMobile
            tests={tests ?? []}
            isLoading={isLoading || isPlaceholderData}
            onTestRenameClick={(testId) => setTestToRenameId(testId)}
            qBankEndpoint={params.qbankEndpoint}
          />
        </div>
      )}
    </DashboardFrame>
  );
}
