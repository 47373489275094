import useTestPageTestSession from "./use-test-page-test-session";
import useTestSlot from "@/hooks/test-slot-hooks/use-testslot";

export const useTestPageSelectedTestSlot = () => {
  const { data: testSession } = useTestPageTestSession();
  return useTestSlot({
    testId: testSession?.id,
    slot: testSession?.selectedSlot,
  });
};
