import useSwitchSelectedTestSlot from "@/hooks/test-hooks/use-switch-selected-testslot";
import { useCallback } from "react";
import useTestPageTestSession from "./use-test-page-test-session";

const useTestPageSwitchSelectedSlot = () => {
  const { data: testSession } = useTestPageTestSession();
  const switchSelectedTestSlot = useSwitchSelectedTestSlot();
  return useCallback(
    (slot: number) => {
      if (!testSession) return;
      if (slot > testSession.slots.length || slot < 0) return;
      switchSelectedTestSlot.mutate({ testId: Number(testSession.id), slot });
    },
    [switchSelectedTestSlot, testSession],
  );
};

export default useTestPageSwitchSelectedSlot;

export const useTestPageRelativeSwitchSelectedSlot = () => {
  const { data: test } = useTestPageTestSession();

  const selectedSlot = test?.selectedSlot;

  const switchSelectedTestSlot = useTestPageSwitchSelectedSlot();

  return useCallback(
    ({ offset }: { offset: number }) => {
      if (selectedSlot === undefined) return;
      return switchSelectedTestSlot(selectedSlot + offset);
    },
    [selectedSlot, switchSelectedTestSlot],
  );
};

export const useTestPageSwitchToNextSlot = () => {
  const relativeSwitch = useTestPageRelativeSwitchSelectedSlot();
  const mutate = useCallback(() => {
    return relativeSwitch({ offset: 1 });
  }, [relativeSwitch]);
  return mutate;
};

export const useTestPageSwitchToPreviousSlot = () => {
  const relativeSwitch = useTestPageRelativeSwitchSelectedSlot();
  const mutate = useCallback(() => {
    return relativeSwitch({ offset: -1 });
  }, [relativeSwitch]);
  return mutate;
};
