import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import { LoadingPage } from "@/components/LoadingPage";
import React, { PropsWithChildren, useEffect } from "react";
import NbmeLayout from "@/components/nbme-layout";
import useResumeTest from "@/hooks/test-hooks/use-resume-test";
import { NotFoundPage } from "@/components/NotFoundPage";
import { zodValidator } from "@tanstack/zod-adapter";

const testPageOptionsSchema = z.object({ slot: z.number().nullable().optional() });
export const Route = createFileRoute("/tests/$testId")({
  validateSearch: zodValidator(testPageOptionsSchema),
  component: TestLayout,
});

const PureTestPage = React.memo(TestPage);
function TestLayout() {
  const params = Route.useParams();
  const { slot } = Route.useSearch();
  const testId = Number(params.testId);
  const { mutate: switchSelectedTestSlot } = useSwitchSelectedTestSlot();
  const { data: testSession, isLoading: isTestSessionLoading, error: testSessionError } = useTestSession({ testId });
  const { isLoading: isTestSlotLoading } = useTestSlot({ testId: testSession?.id, slot: testSession?.selectedSlot })
  useTitle(testSession?.name ? `Test: ${testSession?.name}` : "Loading...");

  const resumeTest = useResumeTest();

  useEffect(() => {
    if (testSession) {
      resumeTest({ testId });
    }
  }, [testId, resumeTest, testSession]);

  useEffect(() => {
    if (testSession && (slot || slot === 0))
      switchSelectedTestSlot({ testId, slot });
  }, [slot, testSession, switchSelectedTestSlot, testId]);

  if (isTestSessionLoading || isTestSlotLoading) return <LoadingPage />;
  if (testSessionError && testSessionError.data?.code === "NOT_FOUND") {
    return <NotFoundPage />;
  }

  return (
    <NbmeLayout questionBankEndpoint={testSession?.questionBankEndpoint ?? ''}>
      <PureTestPage />
    </NbmeLayout>
  );
}

/* Message for future you....
 * Everything here has been thought out,
 * please do not change anything you see here, any padding value
 * or anything even when you don't understand it, everything here
 * was made for a reason...*/
import { NbmeExplanation } from "@/components/NbmeExplanation";
import { NbmeQuestion } from "@/components/NbmeQuestion";
import useNbmeKeyboardShortcuts from "@/hooks/nbme/use-nbme-keyboard-shortcuts";
import {
  useNbmeLineWidth,
  useNbmePadding,
  useNbmeSplitView,
} from "@/utils/stores/nbmeLayoutStore";
import { ScrollArea as ScrollAreaPrimitive } from "@/components/common/ScrollArea";
import {
  ResizableHandle,
  ResizablePanel as SplitPanel,
  ResizablePanelGroup as SplitPanelGroup,
} from "@/components/ui/resizable";
import { useOnDesktop } from "@/hooks";
import { ComponentProps } from "react";
import { twMerge } from "tailwind-merge";
import { PropsWithClassName } from "@/types";
import { cn } from "@/lib/utils";
import spreadIf from "@/lib/utils/spread-if";
import useSwitchSelectedTestSlot from "@/hooks/test-hooks/use-switch-selected-testslot";
import { useTitle } from "@/hooks/common/use-title";
import useTestSession from "@/hooks/test-hooks/use-test-session";
import useTestSlot from "@/hooks/test-slot-hooks/use-testslot";

const UnsplitPanel = ({
  children,
  style,
}: PropsWithChildren & Pick<ComponentProps<"div">, "style">) => {
  const { testId } = Route.useParams();
  const [lineWidth] = useNbmeLineWidth();
  const { data: testSession } = useTestSession({ testId: Number(testId) })
  const { data: testSlot } = useTestSlot({ testId: testSession?.id, slot: testSession?.selectedSlot })
  const onDesktop = useOnDesktop();
  return (
    <div
      className={cn(testSlot?.question.abstract && "lg:max-w-full")}
      style={onDesktop ? { maxWidth: `${lineWidth}%`, ...style } : { ...style }}
    >
      {children}
    </div>
  );
};

const ScrollArea = ({
  children,
  className,
  style,
}: PropsWithChildren &
  PropsWithClassName & { shouldShowSplitView: boolean } & Pick<
    ComponentProps<"div">,
    "style"
  >) => (
  <ScrollAreaPrimitive
    rootStyle={style}
    className={cn("group h-full", className)}
    thumbClassName="bg-gray-300 warm:bg-brown-300 dark:bg-neutral-600 group-focus:bg-gray-400 dark:group-focus:bg-neutral-500 group-hover:bg-gray-400 dark:group-hover:bg-neutral-500 transition-colors"
    scrollbarClassName="bg-gray-100 dark:bg-neutral-800 group-focus:bg-gray-200 dark:group-focus:bg-neutral-700 group-hover:bg-gray-200 warm:group-hover:bg-brown-100 dark:group-hover:bg-neutral-700 transition-colors"
  >
    {children}
  </ScrollAreaPrimitive>
);

const UnsplitPanelGroup = ({
  children,
  shouldShowSplitView,
}: PropsWithChildren & {
  shouldShowSplitView: boolean;
}) => (
  <ScrollArea
    shouldShowSplitView={shouldShowSplitView}
    className={cn("flex flex-col justify-start items-center gap-3 w-full")}
  >
    {children}
  </ScrollArea>
);

export default function TestPage() {
  const { testId } = Route.useParams();
  const [padding] = useNbmePadding();
  useNbmeKeyboardShortcuts();
  const onDesktop = useOnDesktop();
  const [splitView] = useNbmeSplitView();
  const { data: testSession } = useTestSession({ testId: Number(testId) });
  const { data: testSlot } = useTestSlot({ testId: testSession?.id, slot: testSession?.selectedSlot })

  const mode = testSession?.mode;
  const submitted = testSession?.submitted;

  const shouldShowExplanation =
    testSlot?.submitted &&
    (mode?.includes("tutor") || (!mode?.includes("tutor") && submitted));

  const shouldShowSplitView =
    onDesktop && splitView && shouldShowExplanation && !testSlot?.question?.abstract;

  const PanelGroup = shouldShowSplitView ? SplitPanelGroup : UnsplitPanelGroup;

  const Panel = shouldShowSplitView ? SplitPanel : UnsplitPanel;

  return (
    <PanelGroup
      shouldShowSplitView={shouldShowSplitView ?? false}
      direction={"horizontal"}
      className={twMerge("w-full h-full")}
    >
      <Panel defaultSize={50} order={1}>
        <ScrollArea
          style={{
            paddingTop: `calc(15px + ${padding.question}px)`,
            paddingRight: `calc((15px + ${padding.question}px) / 2)`,
            paddingLeft: `calc(15px + ${padding.question}px)`,
          }}
          shouldShowSplitView={shouldShowSplitView ?? false}
        >
          <NbmeQuestion />
        </ScrollArea>
      </Panel>
      {shouldShowExplanation && (
        <>
          {shouldShowSplitView && <ResizableHandle withHandle={true} />}
          <Panel className="px-5" defaultSize={50} order={2}>
            <ScrollArea
              style={{
                paddingLeft: `calc((15px + ${padding.explanation}px)${shouldShowSplitView ? " / 2" : ""
                  })`,
                paddingRight: `calc(15px + ${padding.explanation}px)`,
                ...spreadIf(shouldShowSplitView, {
                  paddingTop: `calc(15px + ${padding.explanation}px)`,
                }),
              }}
              shouldShowSplitView={shouldShowSplitView ?? false}
            >
              <NbmeExplanation />
            </ScrollArea>
          </Panel>
        </>
      )}
    </PanelGroup>
  );
}
