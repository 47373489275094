import { GetTestSlotInput } from "@/api/src/server/routers/test-slot-router";
import { trpc } from "@/utils/trpc";

const useTestSlot = (input: Partial<GetTestSlotInput>) => {
  trpc.testSlot.getTestSlot.usePrefetchQuery({
    testId: input.testId ?? 0,
    slot: (input.slot ?? 0) + 1,
  });

  trpc.testSlot.getTestSlot.usePrefetchQuery({
    testId: input.testId ?? 0,
    slot: (input.slot ?? 0) - 1,
  });

  return trpc.testSlot.getTestSlot.useQuery(
    input as Required<GetTestSlotInput>,
    {
      enabled: !!input.testId && input.slot !== undefined,
      placeholderData: (previous) => previous,
    },
  );
};
export default useTestSlot;
