"use client";
import { Button } from "@/components/Button";
import { QuestionBank } from "@/api/types";
import { useNavigate } from "@tanstack/react-router";
import useQuestionBankOverallStatistics from "@/hooks/statistics/use-questionbank-overall-statistics";

const QuestionBankButton = ({ id, endpoint, name }: QuestionBank) => {
  const navigate = useNavigate();
  const { data: overallStatistics, isLoading } = useQuestionBankOverallStatistics({
    id,
  });

  return (
    <Button
      onClick={() =>
        navigate({
          to: "/qbanks/$qbankEndpoint/dashboard",
          from: "/home",
          params: { qbankEndpoint: endpoint },
        })
      }
      key={id}
      className="flex flex-col justify-start items-start text-white bg-primary-500 rounded-2xl p-3 px-6 hover:scale-105 transition-transform"
    >
      <div className="flex flex-row justify-between items-center gap-1 w-full">
        <h3 className="font-bold md:text-sm text-start">{name}</h3>
        {isLoading && <div className="h-5 w-10 rounded-full bg-white"></div>}
        {overallStatistics && !isLoading && (
          <p className="font-semibold whitespace-nowrap text-base w-fit">
            {overallStatistics?.questions.total} <span className="text-xs text-primary-100">Qs</span>
          </p>
        )}
      </div>
    </Button>
  );
};

export default QuestionBankButton;
