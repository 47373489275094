import { createFileRoute, Outlet } from "@tanstack/react-router";
import { DashboardFrame } from "@/components/DashboardFrame";
import {
  PerformanceTestPageBackLink,
  PerformanceTestPageNotesButton,
  PerformanceTestPageQuestionListButton,
  PerformanceTestPageReviewTestButton,
  PerformanceTestPageTabs,
} from "./-components";
import useDashboardQuestionBank from "../../../-dashboard-hooks/use-dashboard-question-bank";

export const Route = createFileRoute(
  "/qbanks/$qbankEndpoint/dashboard/_dashboard/performance/test/$testId",
)({
  component: PerformanceTestLayout,
});

export default function PerformanceTestLayout() {
  const params = Route.useParams();
  const { data: questionBank } = useDashboardQuestionBank();

  return (
    <DashboardFrame className="min-h-[85vh] w-full">
      <div className="w-full flex flex-col justify-start items-center gap-16">
        <div className="w-full flex flex-col justify-start items-center">
          <div className="w-full flex flex-col md:flex-row justify-between items-center px-10 py-8 gap-3 md:gap-0 text-xl md:text-base">
            <PerformanceTestPageBackLink
              qBankId={questionBank?.id ?? ''}
              qBankEndpoint={questionBank?.endpoint ?? ""}
            />
            <div className="flex flex-col md:flex-row justify-end items-center gap-6 w-full">
              <div className="flex flex-col md:flex-row justify-end items-center gap-2 md:gap-4 w-full">
                <PerformanceTestPageNotesButton />
                <PerformanceTestPageQuestionListButton />
              </div>
              <PerformanceTestPageReviewTestButton testId={params.testId} />
            </div>
          </div>
          <div className="w-full px-10">
            <PerformanceTestPageTabs testId={params.testId} />
          </div>
        </div>
        {/*isLoading
          ? (
            <div className="flex flex-col justify-center items-center w-full min-h-[85vh]">
              <LoadingSpinner className="w-16 h-16" />
            </div>
          )
          : children*/}
        <Outlet />
        {/*error && error.data?.code === "PRECONDITION_FAILED" &&
          (
            <DashboardCenterPiece
              icons={[faHourglassStart]}
              label="This test is currently being evaluated, please come back in 10 - 20 seconds"
            />
          )*/}
      </div>
    </DashboardFrame>
  );
}
