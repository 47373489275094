
type SubjectProps = { subjects: string[] };

const TestSubjects = ({ subjects }: SubjectProps) => {
  return (
    <span>
      {subjects.length > 1
        ? "Multiple"
        : (subjects[0] ?? "Not Found")}
    </span>
  );
};

export default TestSubjects;
