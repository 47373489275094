import { usePerformanceTestPageStore } from "./usePerformanceTestPageStore";

export { usePerformanceTestPageStore } from "./usePerformanceTestPageStore";

export { useReportOptions } from "./useReportOptions";
export const usePerformanceTestCurrentPage = () => {
  const page = usePerformanceTestPageStore(state => state.page);
  const setPage = usePerformanceTestPageStore(state => state.setPage);
  return [page, setPage] as const;
}

export const usePerformanceTestQuestionsPerPage = () => {
  const questionsPerPage = usePerformanceTestPageStore(state => state.questionsPerPage);
  const setQuestionsPerPage = usePerformanceTestPageStore(state => state.setQuestionsPerPage);
  return [questionsPerPage, setQuestionsPerPage] as const;

}


