import useChooseTestSlotQuestionChoice from "@/hooks/test-slot-hooks/use-choose-testslot-questionchoice";
import { ChooseTestSlotQuestionChoiceInput } from "@/api/routers/test-slot-router";
import { useCanSubmit } from "@/utils/stores/testStore";
import { useTestPageSelectedTestSlot } from "./use-test-page-selected-test-slot";

const useTestPageChooseTestSlotQuestionChoice = () => {
  const { data: selectedSlot } = useTestPageSelectedTestSlot();
  const chooseChoice = useChooseTestSlotQuestionChoice();
  const [_, setCanSubmit] = useCanSubmit();
  return ({
    questionChoiceId,
  }: Omit<ChooseTestSlotQuestionChoiceInput, "testSlotId">) => {
    if (selectedSlot) {
      setCanSubmit(false);
      chooseChoice({ testSlotId: selectedSlot.id, questionChoiceId }).finally(
        () => setCanSubmit(true),
      );
    }
  };
};

export default useTestPageChooseTestSlotQuestionChoice;
