import PreviousTestMobile from "./previous-test-mobile";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { twMerge } from "tailwind-merge";
import { GetPreviousTestsOutput } from "@/api/src/server/routers";

interface PreviousTestsMobileProps {
  onTestRenameClick: (testId: number) => void;
  qBankEndpoint: string;
  tests: GetPreviousTestsOutput['tests'];
  isLoading: boolean;
}

const PreviousTestsMobileTable = ({
  onTestRenameClick,
  qBankEndpoint,
  tests,
  isLoading,
}: PreviousTestsMobileProps) => {

  return (
    <div
      className={twMerge(
        "w-full flex flex-col justify-start items-center px-5 gap-5",
      )}
    >
      {(isLoading && tests.length === 0) && (
        <div className="w-20 h-20 absolute left-[50%] top-[50%] translate-y-[-50%] translate-x-[-50%]">
          <LoadingSpinner />
        </div>
      )}
      {tests.map((test) => (
        <PreviousTestMobile
          isLoading={isLoading}
          test={test}
          testName={test.name}
          qBankEndpoint={qBankEndpoint}
          onTestRenameClick={onTestRenameClick}
          key={test.id}
          testId={test.id}
          questionIds={test.questions}
        />
      ))}
    </div>
  );
};

export default PreviousTestsMobileTable;
