import { NbmeNavbarButtonBase } from "./NbmeNavbarButtonBase";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faFlag } from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { Skeleton } from "./ui/skeleton";
import useTestPageMarkSelectedTestSlotQuestion from "@/routes/tests/$testId/-test-page-hooks/use-test-page-mark-test-selectedslot-question";
import { useTestPageSelectedTestSlot } from "@/routes/tests/$testId/-test-page-hooks/use-test-page-selected-test-slot";

interface NbmeQuestionMarkButtonProps {
  className?: string;
}

export const NbmeMarkQuestionButton = ({
  className,
}: NbmeQuestionMarkButtonProps) => {
  const { data: slot } = useTestPageSelectedTestSlot();
  const question = slot?.question;
  const markQuestion = useTestPageMarkSelectedTestSlotQuestion();

  return (
    <NbmeNavbarButtonBase onClick={markQuestion} className={className}>
      <div className="flex flex-col md:flex-row text-white justify-center items-center">
        <div className="relative ml-2">
          {!question ? (
            <Skeleton className="w-10 h-10 rounded-primary" />
          ) : (
            <>
              <FontAwesomeIcon icon={faFlag} className="w-8 h-8" />
              {question.isMarked && (
                <FontAwesomeIcon
                  icon={faCheck}
                  className={twMerge(
                    "w-2.5 h-2.5 absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-90%] text-white bg-nbme-primary-700 warm:bg-brown-800 dark:bg-nbme-primary-dark-900 p-0.5 rounded-full",
                  )}
                />
              )}
            </>
          )}
        </div>
        <p className="text-xs md:text-sm font-semibold ml-2">Mark</p>
      </div>
    </NbmeNavbarButtonBase>
  );
};
