import { QuestionBankPerformanceReportsInput } from "@/api/src/server/routers/statistics/types";
import { trpc } from "@/utils/trpc";

const useQuestionBankPerformanceReports = (
  input: QuestionBankPerformanceReportsInput,
) => {
  trpc.statistics.getQuestionBankPerformanceReports.usePrefetchQuery({
    ...input,
    page: input.page + 1,
  });
  trpc.statistics.getQuestionBankPerformanceReports.usePrefetchQuery({
    ...input,
    page: input.page + 2,
  });
  return trpc.statistics.getQuestionBankPerformanceReports.useQuery(input, {
    enabled: !!input.qBankId,
    placeholderData: (previous) => previous,
  });
};

export default useQuestionBankPerformanceReports;
