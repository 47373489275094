import { SearchInput } from "@/components/SearchInput";
import { usePreviousTestsPageSearch } from "@/utils/stores/previousTestsPageStore";
import { useState } from "react";
import { useTimer } from "use-timer";

export const PreviousTestsPageQueryInput = ({ isLoading }: { isLoading?: boolean }) => {
  const [query, setQuery] = usePreviousTestsPageSearch();
  const [draft, setDraft] = useState(query);
  const timer = useTimer({
    autostart: !!draft,
    onTimeOver: () => {
      setQuery(draft);
    },
    endTime: 2,
  })
  return (
    <SearchInput
      variant="dashboard"
      value={draft}
      isLoading={isLoading}
      onChange={(e) => {
        timer.start();
        if (!e.target.value) {
          setQuery('');
        }
        setDraft(e.target.value)
      }}
      className="warm:bg-white"
    />
  );
};
