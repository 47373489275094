import { GetTestSessionOutput } from "@/api/src/server/routers";
import { trpc } from "@/utils/trpc";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@trpc/react-query";
import { toast } from "sonner";

const useSwitchSelectedTestSlot = () => {
  const queryClient = useQueryClient();
  return trpc.test.switchSelectedSlot.useMutation({
    onMutate: ({ slot, testId }) => {
      const testSessionQueryKey = getQueryKey(
        trpc.test.getTestSession,
        { testId },
        "query",
      );
      queryClient.cancelQueries({ queryKey: testSessionQueryKey });
      let oldTestSession: GetTestSessionOutput | undefined = undefined;
      queryClient.setQueryData<GetTestSessionOutput>(
        testSessionQueryKey,
        (previous) => {
          if (!previous) return previous;
          if (slot < 0 || slot > previous.slotCount) return previous;
          oldTestSession = previous;
          return { ...previous, selectedSlot: slot };
        },
      );
      //Function to rollback
      return {
        testSessionQueryKey,
        rollback: () => {
          queryClient.setQueryData<GetTestSessionOutput>(
            testSessionQueryKey,
            (previous) => {
              if (!previous) return previous;
              if (!oldTestSession) return;
              previous.selectedSlot = oldTestSession.selectedSlot;
              return previous;
            },
          );
        },
      };
    },
    onError: (err, variables, ctx) => {
      toast.error(
        `Could not switch questions.., an error ocurred: ${err.message}`,
      );
      ctx?.rollback?.();
    },
  });
};

export default useSwitchSelectedTestSlot;
