import useSubmitTest from "@/hooks/test-hooks/use-submit-test";
import useTestPageTestSession from "./use-test-page-test-session";

const useTestPageSubmitTest = () => {
  const { data: test } = useTestPageTestSession();
  const submitTest = useSubmitTest();

  return () => {
    if (!test) return;
    submitTest({ testId: test.id });
  };
};

export default useTestPageSubmitTest;
