
type SystemProps = { systems: string[] };

const TestSystems = ({ systems }: SystemProps) => {
  return (
    <span>
      {systems.length > 1 ? "Multiple" : (systems[0] ?? "Not Found")}
    </span>
  );
};

export default TestSystems;
