import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { NbmeNavbar } from "@/components/NbmeNavbar";
import { smoothSpring } from "@/assets/framer/springs";
import { NbmeSidebar } from "@/components/NbmeSidebar";
import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { NbmeCalculator } from "@/components/NbmeCalculator";
import { NbmeConfirmOmissionDialog } from "@/components/NbmeConfirmOmissionDialog";
import { NbmeEndTestDialog } from "@/components/NbmeEndTestDialog";
import { NbmeFeedbackWindow } from "@/components/NbmeFeedbackWindow";
import { NbmeHighlightWarningDialog } from "@/components/NbmeHighlightWarningDialog";
import { NbmeHint } from "@/components/NbmeHint";
import { NbmeNotebookWindow } from "@/components/NbmeNotebookWindow";
import { NbmeQuestionNoteWindow } from "@/components/NbmeQuestionNoteWindow";
import { NbmeSettingsBar } from "@/components/NbmeSettingsBar";
import { NbmeSuspendTestDialog } from "@/components/NbmeSuspendTestDialog";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import { useNbmeLayoutStore } from "@/utils/stores";
import { useOnMobile } from "@/hooks";
import { useNbmeAllocateSpaceForSidebar } from "@/utils/stores/nbmeLayoutStore";
import useNbmeGestures from "@/hooks/nbme/use-nbme-gestures";
import TestPageNightModeUpdater from "@/routes/tests/$testId/-test-page-components/test-page-night-mode-updater";
import useNbmeSidebarVisible from "@/components/NbmeSidebar/hooks/use-nbme-sidebar-visible";
import { useBreakPoint } from "@/utils/hooks";
import { BreakPoints } from "@/types";
import { NbmeContextMenu } from "@/ncomponents/nbme/context-menu";
import { useTestPageSelectedTestSlot } from "@/routes/tests/$testId/-test-page-hooks/use-test-page-selected-test-slot";

type NbmeLayoutProps = { questionBankEndpoint: string } & PropsWithChildren;
const NbmeLayout = ({ children, questionBankEndpoint }: NbmeLayoutProps) => {
  const { data: slot } = useTestPageSelectedTestSlot()
  const hints = slot?.hints;
  const onMobile = useOnMobile();
  const [allocateSpaceForSidebar] = useNbmeAllocateSpaceForSidebar();
  const {
    selectionEnabled,
    hints: hintVisibility,
    toggleHint,
    navbarWidth: sidebarWidth,
  } = useNbmeLayoutStore((state) => ({
    selectionEnabled: state.selectionEnabled,
    fontSizeFactor: state.settings.fontSizeFactor,
    hints: state.hints,
    toggleHint: state.toggleHint,
    navbarWidth: state.sidebarWidth,
  }));
  const [sidebarVisible, setSidebarVisible] = useNbmeSidebarVisible();
  const bindNbmeGestures = useNbmeGestures();
  const onSidebarToggle = useCallback(
    () => setSidebarVisible(!sidebarVisible),
    [setSidebarVisible, sidebarVisible],
  );
  const breakPoint = useBreakPoint();
  useEffect(() => {
    if (breakPoint < BreakPoints.Large) setSidebarVisible(false);
    else setSidebarVisible(true);
  }, [breakPoint]);
  return (
    <>
      <div
        className={twMerge(
          "flex flex-row min-w-full justify-between items-start selection:bg-nbme-primary-700 outline-none h-screen dark:text-white text-gray-800 warm:text-brown-950",
          !selectionEnabled && "select-none",
        )}
        style={{ touchAction: "none" }}
        {...bindNbmeGestures()}
      >
        <TestPageNightModeUpdater />
        <NbmeContextMenu />
        <NbmeSuspendTestDialog />
        <NbmeConfirmOmissionDialog />
        <NbmeEndTestDialog questionBankEndpoint={questionBankEndpoint} />
        <NbmeCalculator />
        <NbmeHighlightWarningDialog />
        <NbmeNotebookWindow />
        <NbmeQuestionNoteWindow />
        <NbmeSettingsBar />
        {hints &&
          hints.map((h) => {
            return (
              <NbmeHint
                hint={h}
                visible={hintVisibility[h.id] || false}
                onCloseClicked={() =>
                  toggleHint({ hintId: h.id, value: false })
                }
                key={h.id}
              />
            );
          })}

        <NbmeFeedbackWindow />
        <NbmeSidebar visible={sidebarVisible} onToggle={onSidebarToggle} />
        <motion.div
          className="flex flex-col justify-between items-between h-full min-w-full lg:min-w-fit lg:flex-[90]"
          style={{ touchAction: "none" }}
          transition={{ ...smoothSpring }}
          initial={{ paddingLeft: 0 }}
          animate={
            sidebarVisible &&
              !onMobile &&
              allocateSpaceForSidebar &&
              sidebarWidth
              ? { paddingLeft: sidebarWidth }
              : { paddingLeft: 0 }
          }
        >
          <NbmeNavbar onSidebarToggle={onSidebarToggle}>
            <div
              className={twMerge(
                "flex-[90] overflow-hidden bg-nbme-primary-50 warm:bg-egg-sour-100 dark:bg-nbme-primary-dark-900",
                !selectionEnabled && "pointer-events-none",
              )}
            >
              {children}
            </div>
          </NbmeNavbar>
        </motion.div>
      </div>
    </>
  );
};
export default React.memo(NbmeLayout);
