import {
  GetPreviousTestsInput,
  GetPreviousTestsOutput,
} from "@/api/src/server/routers";
import { queryClient, trpc } from "@/utils/trpc";
import { getQueryKey } from "@trpc/react-query";
import { toast } from "sonner";

const useRenameTest = (input: GetPreviousTestsInput) => {
  return trpc.test.renameTest.useMutation({
    onMutate: ({ name, testId }) => {
      const previousTestsQueryKey = getQueryKey(
        trpc.test.getPreviousTests,
        input,
        "query",
      );
      const oldData = queryClient.getQueryData<GetPreviousTestsOutput>(
        previousTestsQueryKey,
      );
      console.log(oldData);
      if (!oldData) return;
      const oldName = oldData.tests.find((t) => t.id === testId)?.name ?? "";

      queryClient.setQueryData<GetPreviousTestsOutput>(previousTestsQueryKey, {
        ...oldData,
        tests: oldData.tests.map((t) => {
          console.log(t);
          if (t.id !== testId) return t;
          t.name = name;
          return t;
        }),
      });

      //Function to reverse state on error
      return () => {
        queryClient.setQueryData<GetPreviousTestsOutput>(
          previousTestsQueryKey,
          (previous) => {
            if (previous)
              return {
                ...previous,
                tests: previous.tests.map((t) => {
                  if (t.id !== testId) return t;
                  t.name = oldName;
                  return t;
                }),
              };
          },
        );
      };
    },
    onError: (err, variables, reverse) => {
      reverse?.();
      toast.error(
        `Could not rename test to: ${variables.name} , an error ocurred: ${err.message}`,
      );
    },
  });
};
export default useRenameTest;
