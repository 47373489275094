import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { TestPerformance } from "../-types";
import { Setter } from "@/types";
import {
  DashboardPerformanceSelectItemTypeWithMarked,
} from "@/routes/qbanks/$qbankEndpoint/dashboard/-components/DashboardPerformanceSelect";
import { createJSONStorage, persist } from "zustand/middleware";

interface PerformanceTestPageStoreState {
  testPerformance?: TestPerformance;
  isTestPerformanceLoading: boolean;
  options: DashboardPerformanceSelectItemTypeWithMarked[];
  page: number;
  questionsPerPage:5 | 10 | 25 | 50 | 100;
}

interface PerformanceTestPageStoreActions {
  loadTestPerformance: Setter<PerformanceTestPageStoreState["testPerformance"]>;
  setIsTestPerformanceLoading: Setter<
    PerformanceTestPageStoreState["isTestPerformanceLoading"]
  >;
  setOptions: Setter<PerformanceTestPageStoreState["options"]>;
  setPage: Setter<PerformanceTestPageStoreState['page']>;
  setQuestionsPerPage: Setter<PerformanceTestPageStoreState['questionsPerPage']>;
}

type PerformanceTestPageStore = PerformanceTestPageStoreState &
  PerformanceTestPageStoreActions;

export const usePerformanceTestPageStore = create(
  persist(
    immer<PerformanceTestPageStore>((set) => {
      const setValue =
        (
          key: keyof PerformanceTestPageStoreState,
        ): Setter<PerformanceTestPageStoreState[typeof key]> =>
        (value) =>
          set((state) => {
            //@ts-ignore
            state[key] = value;
          });

      return {
        testPerformance: undefined,
        loadTestPerformance: setValue("testPerformance"),
        isTestPerformanceLoading: false,
        setIsTestPerformanceLoading: setValue("isTestPerformanceLoading"),
        options: ["Correct", "Incorrect", "Marked", "Omitted"],
        setOptions: setValue("options"),
        page: 1,
        setPage: setValue("page"),
        questionsPerPage: 10,
        setQuestionsPerPage: setValue("questionsPerPage"),
      };
    }),
    {
      name: "performance-test-page",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({ options: state.options }),
    },
  ),
);
