"use client";
import { DashboardFrame } from "@/components/DashboardFrame";
import DashboardSection from "@/components/DashboardSection";
import { DashboardUsageStatsCircle } from "@/components/DashboardUsageStatsCircle";
import DashboardStatsSection from "@/components/DashboardStatsSection";
import { Separator } from "@/components/Separator";
import { titlize } from "@/utils/common/titlize";
import { useRequiresUser } from "@/utils/customHooks";

import { useDashboardLayoutStore } from "@/utils/stores/dashboardLayoutStore";
import { useEffect } from "react";
import { DashboardScoreStatsCircle } from "@/components/DashboardScoreStatsCircle";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";
import { DashboardPredictedScoreSection } from "./DashboardPredictedScoreSection";
import useQuestionBankOverallStatistics from "@/hooks/statistics/use-questionbank-overall-statistics";

const OverallStatsPage = () => {
  const user = useRequiresUser()!;
  const setHeaderText = useDashboardLayoutStore((state) => state.setHeaderText);
  const { data: questionBank } = useDashboardQuestionBank();

  const {
    data: overallStatistics,
    isLoading: isOverallStatisticsLoading,
  } = useQuestionBankOverallStatistics({
    id: questionBank?.id ?? "",
  });

  useEffect(() => {
    setHeaderText(
      `Welcome ${titlize(user?.name.split(" ").slice(0, 2).join(" "))}`,
    );
    return () => setHeaderText("");
  }, [setHeaderText, user?.name]);

  return (
    <DashboardFrame className="py-12 px-6 h-full min-h-full">
      <DashboardSection>
        <div className="flex flex-col justify-start items-start w-full gap-10">
          <div className="flex flex-col justify-start items-start w-full">
            <h1 className="mb-1.5 text-lg">Statistics</h1>
            <Separator orientation="horizontal" className="bg-gray-100" />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 md:grid-rows-2 grid-flow-row w-full gap-20">
            <div className="flex flex-row justify-center items-center">
              <DashboardScoreStatsCircle
                isLoading={isOverallStatisticsLoading}
                omitted={overallStatistics?.questions.omitted ?? 0}
                incorrect={overallStatistics?.questions.incorrect ?? 0}
                correct={overallStatistics?.questions.correct ?? 0}
                total={overallStatistics?.questions.used ?? 0}
              />
            </div>
            <DashboardStatsSection
              isLoading={isOverallStatisticsLoading}
              title="Your Score"
              items={[
                { text: "Total Correct", count: overallStatistics?.questions.correct ?? 0 },
                {
                  text: "Total Incorrect",
                  count: overallStatistics?.questions.incorrect ?? 0,
                },
                { text: "Total Omitted", count: overallStatistics?.questions.omitted ?? 0 },
              ]}
            />
            <DashboardStatsSection
              isLoading={isOverallStatisticsLoading}
              title="Answer Changes"
              items={[
                {
                  text: "Correct to Incorrect",
                  count: overallStatistics?.answerChanges.correctToIncorrect ?? 0,
                },
                {
                  text: "Incorrect to Correct",
                  count: overallStatistics?.answerChanges.incorrectToCorrect ?? 0,
                },
                {
                  text: "Incorrect to Incorrect",
                  count: overallStatistics?.answerChanges.incorrectToIncorrect ?? 0,
                },
              ]}
            />
            <div className="flex flex-row justify-center items-center">
              <DashboardUsageStatsCircle
                used={overallStatistics?.questions.used ?? 0}
                total={overallStatistics?.questions.total ?? 0}
                isLoading={isOverallStatisticsLoading}
              />
            </div>
            <DashboardStatsSection
              isLoading={isOverallStatisticsLoading}
              title="QBank Usage"
              items={[
                { text: "Used Questions", count: overallStatistics?.questions.used ?? 0 },
                {
                  text: "Unused Questions",
                  count: overallStatistics?.questions.unused ?? 0,
                },
                { text: "Total Questions", count: overallStatistics?.questions.total ?? 0 },
              ]}
            />
            <DashboardStatsSection
              isLoading={isOverallStatisticsLoading}
              title="Test Count"
              items={[
                { text: "Tests Created", count: overallStatistics?.tests.total ?? 0 },
                {
                  text: "Tests Completed",
                  count: overallStatistics?.tests.completed ?? 0,
                },
                { text: "Suspended Tests", count: overallStatistics?.tests.suspended ?? 0 },
              ]}
            />
            <div className="hidden md:block"></div>
            <div className="hidden md:block"></div>
            <DashboardPredictedScoreSection />
          </div>
        </div>
      </DashboardSection >
    </DashboardFrame>
  );
};

export default OverallStatsPage;
