// YES this is a duplicate this was not a mistake, I am absolutely
// aware that this is trash but I don't have time to properly code this
// sorry.... future you...

import { useEffect, useMemo, useState } from "react";
import Mark from "mark.js";
import useHighlightMarkers from "@/hooks/highlightmarker-hooks/use-highlightmarkers";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { useTestPageSelectedTestSlot } from "@/routes/tests/$testId/-test-page-hooks/use-test-page-selected-test-slot";

export const useHighlightMarker = (
  explanationId: string,
  explanationHtml: string,
  questionSource: string,
) => {
  const [highlightedHtml, setHighlightedHtml] =
    useState<string>(explanationHtml);
  const { data: slot } = useTestPageSelectedTestSlot();
  const highlights = slot?.question.explanation.highlights;
  const { data: highlightMarkersArrayMap } = useHighlightMarkers();
  const highlightMarkers = useMemo(
    () => ArrayMapUtils.toArray(highlightMarkersArrayMap),
    [highlightMarkersArrayMap],
  );

  useEffect(() => {
    if (!explanationHtml) return;
    const domParser = new DOMParser();
    const parsedExplanationDocument = domParser.parseFromString(
      explanationHtml,
      "text/html",
    ).documentElement;

    const explanationBody = parsedExplanationDocument.querySelector("body")!;

    if (questionSource.toLowerCase() === "nbme") {
      //Separate different text nodes with breaks into
      //different paragraphs
      explanationBody.childNodes.forEach((node) => {
        const TEXT_NODE = 3;
        if (node.nodeType === TEXT_NODE) {
          const breaks = node.textContent?.split(/[\u00A0]/) ?? [];
          const newNodes: Node[] = [];
          for (const brk of breaks) {
            const newP = document.createElement("p");
            newP.innerHTML = brk;
            newNodes.push(newP);
          }
          node.replaceWith(...newNodes);
        }
      });

      //Connect nodes with EM or SPAN or SUP to the previous paragraphs
      //(so as to not get separate paragraphs)
      explanationBody.querySelectorAll("em, span, sup").forEach((node) => {
        try {
          if (node.previousElementSibling?.tagName === "P") {
            node.previousSibling?.appendChild(node.cloneNode(true));
            node.remove();
          }
        } catch {
          console.error(`Could not append child to previous sibling`);
        }
      });

      //Connect nodes with P to the previous EM or SPAN
      //(so as to not get separate paragraphs)
      explanationBody.querySelectorAll("p").forEach((node) => {
        const newParent = document.createElement("span");
        if (
          node.lastElementChild?.tagName === "EM" ||
          node.lastElementChild?.tagName === "SPAN" ||
          node.lastElementChild?.tagName === "SUP"
        ) {
          if (node.nextSibling) {
            const newSpan = document.createElement("span");
            newSpan.innerHTML = node.nextElementSibling?.innerHTML ?? "";
            node.appendChild(newSpan);
            node.nextElementSibling?.remove();
            newParent.replaceChildren(node.cloneNode(true));
            node.replaceWith(newParent);
          }
        }
      });
    }

    parsedExplanationDocument.querySelectorAll("a").forEach((hint) => {
      hint.setAttribute("data-id", hint.getAttribute("href") ?? "");
    });

    const markInstance = new Mark(parsedExplanationDocument);

    markInstance.unmark({
      done: () => {
        //Fake mark so that mark.js can make it's magic
        //Don't touch this... ever... without it highlights
        //don't land on the right spot for some reason
        markInstance.markRanges([{ start: 0, length: 1 }], {
          each(elem) {
            elem.classList.add("bg-transparent", "dark:text-neutral-50");
          },
        });
        highlights?.forEach((highlight) => {
          markInstance.markRanges(
            [
              {
                start: highlight.start,
                length: highlight.end - highlight.start,
              },
            ],
            {
              className:
                "rounded-sm cursor-pointer font-semibold dark:text-neutral-800",
              each: (node) => {
                node.setAttribute("data-id", highlight.id);
                node.setAttribute(
                  "data-explanation-id",
                  explanationId.toString(),
                );
                node.setAttribute("data-marker-id", highlight.markerId);
                node.setAttribute(
                  "style",
                  `background-color: ${
                    highlightMarkers.find((hm) => hm.id === highlight.markerId)
                      ?.color
                  }`,
                );
              },
            },
          );
        });
        setHighlightedHtml(
          parsedExplanationDocument.querySelector("body")!.innerHTML,
        );
      },
    });
  }, [highlights, explanationId, explanationHtml]);

  return highlightedHtml;
};
