import { cn } from '@/lib/utils'
import { motion } from 'framer-motion'

export default function PulsatingDots({ className, dotClassName }: { className?: string, dotClassName?: string }) {
  return (
    <div className={cn("flex items-center justify-center", className)}>
      <div className="flex space-x-2">
        <motion.div
          className={cn("h-3 w-3 rounded-full bg-red-500", dotClassName)}
          animate={{
            scale: [1, 1.5, 1],
            opacity: [0.5, 1, 0.5],
          }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            repeat: Infinity,
          }}
        />
        <motion.div
          className={cn("h-3 w-3 rounded-full bg-red-500", dotClassName)}
          animate={{
            scale: [1, 1.5, 1],
            opacity: [0.5, 1, 0.5],
          }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            repeat: Infinity,
            delay: 0.3,
          }}
        />
        <motion.div
          className={cn("h-3 w-3 rounded-full bg-red-500", dotClassName)}
          animate={{
            scale: [1, 1.5, 1],
            opacity: [0.5, 1, 0.5],
          }}
          transition={{
            duration: 1,
            ease: 'easeInOut',
            repeat: Infinity,
            delay: 0.6,
          }}
        />
      </div>
    </div>
  )
}
