import useMarkQuestion from "@/hooks/question-hooks/use-mark-question";
import { useCallback } from "react";
import { useTestPageSelectedTestSlot } from "./use-test-page-selected-test-slot";

const useTestPageMarkSelectedTestSlotQuestion = () => {
  const { data: testSlot } = useTestPageSelectedTestSlot();
  const selectedQuestion = testSlot?.question;
  const markQuestion = useMarkQuestion();
  return useCallback(() => {
    if (!selectedQuestion) return;
    markQuestion({
      questionId: selectedQuestion.id,
      marked: !selectedQuestion.isMarked,
    });
  }, [markQuestion, selectedQuestion]);
};

export default useTestPageMarkSelectedTestSlotQuestion;
