import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { TestSliceCreator, testSliceState } from ".";
import { Test } from "@/api/types";
import { clamp } from "@/utils/common/clamp";
import switchSelectedSlotManipulator from "@/models/test-model/test-model-manipulators/switch-selected-testslot";
import setTestModeManipulator from "@/models/test-model/test-model-manipulators/set-test-mode";
import { queryClient, trpc, trpcProxyClient } from "@/utils/trpc";
import handleTRPCMutationError from "@/utils/trpc/handle-trpc-mutation-error";
import handleTRPCMutationSuccess from "@/utils/trpc/handle-trpc-mutation-success";
import suspendTestManipulator from "@/models/test-model/test-model-manipulators/suspend-test";
import submitTestManipulator from "@/models/test-model/test-model-manipulators/submit-test";
import resumeTestManipulator from "@/models/test-model/test-model-manipulators/resume-test";
import createTestManipulator from "@/models/test-model/test-model-manipulators/create-test";
import createTestTestSlotsManipulator from "@/models/testslot-model/testslot-model-manipulators/create-test-testslots";
import {
  useCreateTestPageStore,
  usePreviousTestsPageStore,
} from "@/utils/stores";
import { DateTime, Duration } from "luxon";
import { calculateTestEndsAtIncrementInSecondsAfterSlotSelection } from "@/services/test";
import calculateTestScore from "@/models/test-model/test-model-manipulators/calculate-test-score";
import { TestWithArticles } from "@/models";
import { reset } from "@/api/src/server/routers/auth/mutations";
import { getQueryKey } from "@trpc/react-query";

const createTestSlice: TestSliceCreator = (set, get) => {
  return {
    ...testSliceState,
    addTest: (test) =>
      set((state) => {
        const isTestDeleted = ArrayMapUtils.includes(state.deletedTests, test);
        if (isTestDeleted) return;
        ArrayMapUtils.push(state.tests, [test]);
      }),
    addTests: (tests) =>
      set((state) => {
        const isNotDeleted = (test: Test) =>
          !ArrayMapUtils.includes(state.deletedTests, test);
        ArrayMapUtils.push(state.tests, tests.filter(isNotDeleted));
      }),
    deleteTest: ({ testId, resetQuestions }, opts) => {
      let state = get();
      let blueprintWasUnused = false;

      const testToDelete = ArrayMapUtils.find(state.tests, testId);
      if (!testToDelete) return;
      const testOldTestSlots = ArrayMapUtils.toArray(
        ArrayMapUtils.filter(state.testSlots, (ts) => ts.testId === testId),
      );
      const oldQuestionHeaders = ArrayMapUtils.toArray(
        ArrayMapUtils.filter(
          state.questionHeaders,
          (qh) => !!testOldTestSlots.find((s) => s.questionId === qh.id),
        ),
      );
      const oldQuestions = ArrayMapUtils.toArray(
        ArrayMapUtils.filter(
          state.questions,
          (qh) => !!testOldTestSlots.find((s) => s.questionId === qh.id),
        ),
      );

      set((state) => {
        const test = ArrayMapUtils.find(state.tests, testId);
        if (!test) return;
        ArrayMapUtils.push(state.deletedTests, [test]);
        ArrayMapUtils.delete(state.tests, testId);
      });

      state = get();
      const blueprintId = testToDelete.blueprintId;
      if (
        blueprintId &&
        !ArrayMapUtils.find(state.tests, (t) => t.blueprintId === blueprintId)
      ) {
        state.unuseBlueprint({ blueprintId });
        blueprintWasUnused = true;
      }
      if (resetQuestions) {
        const testQuestionIds = testOldTestSlots.map((ts) => ts.questionId);
        state.resetQuestionHeaders(testQuestionIds);
      }
      const previousTestsPageState = usePreviousTestsPageStore.getState();

      if (opts?.local) return;
      trpcProxyClient.test.deleteTest
        .mutate({ testId, resetQuestions })
        .then(
          handleTRPCMutationSuccess({ mutationPastPrinciple: "deleted test" }),
        )
        .catch(
          handleTRPCMutationError(
            { mutationDescription: "delete test" },
            () => {
              const state = get();
              const testToRestore = ArrayMapUtils.find(
                state.deletedTests,
                testId,
              );
              if (!testToRestore) return;
              set((state) => {
                ArrayMapUtils.push(state.tests, [testToRestore]);
                ArrayMapUtils.delete(state.deletedTests, testId);
                if (!resetQuestions) return;
                ArrayMapUtils.update(state.questions, {
                  ids: oldQuestions.map((q) => q.id),
                  updater: (q) => ({
                    ...q,
                    ...oldQuestions.find((oq) => oq.id === q.id)!,
                  }),
                });
                ArrayMapUtils.update(state.questionHeaders, {
                  ids: oldQuestionHeaders.map((q) => q.id),
                  updater: (q) => ({
                    ...q,
                    ...oldQuestionHeaders.find((oq) => oq.id === q.id)!,
                  }),
                });
              });
              const blueprintId = testToRestore.blueprintId;
              if (blueprintId && blueprintWasUnused) {
                state.useBlueprint({ blueprintId });
              }
            },
          ),
        );
    },
    renameTest: ({ testId, name }) => {
      set((state) => {
        ArrayMapUtils.update(state.tests, {
          ids: [testId],
          updater: (test) => ({ ...test, name }),
        });
      });
    },
    switchSelectedTestSlot: ({ testId, slot }, opts) => {
      let state = get();
      const oldTestState = ArrayMapUtils.find(state.tests, testId);
      if (!oldTestState) return;
      const lastSelectedTestSlot = ArrayMapUtils.find(
        get().testSlots,
        (ts) =>
          ts.order === oldTestState.selectedSlot &&
          ts.testId === oldTestState.id,
      );
      if (!lastSelectedTestSlot) return;
      const clampedNewlySelectedSlotOrder = clamp({
        num: slot,
        min: 0,
        max: oldTestState.slots.length - 1,
      });
      set((state) => {
        ArrayMapUtils.update(state.tests, {
          ids: [oldTestState.id],
          updater: (test) => {
            return {
              ...test,
              selectedSlot: clampedNewlySelectedSlotOrder,
            };
          },
        });
      });
      state = get();
      const newlySelectedSlot = ArrayMapUtils.find(
        state.testSlots,
        (ts) =>
          ts.order === clampedNewlySelectedSlotOrder && ts.testId === testId,
      );
      if (!newlySelectedSlot) return;
      get().selectTestSlot({ testSlotId: newlySelectedSlot.id });
      if (opts?.local) return;
      trpcProxyClient.test.switchSelectedSlot
        .mutate({ testId, slot: clampedNewlySelectedSlotOrder })
        .catch(
          handleTRPCMutationError(
            { mutationDescription: "switch questions" },
            () => {
              const state = get();
              state.switchSelectedTestSlot(
                {
                  testId,
                  slot: oldTestState.selectedSlot,
                },
                { local: true },
              );
            },
          ),
        );
    },
    setTestMode: ({ testId, mode }) => {
      const oldState = get();
      const oldTest = ArrayMapUtils.find(oldState.tests, testId);
      if (!oldTest) return;
      const oldTestSlots = ArrayMapUtils.toArray(
        ArrayMapUtils.filter(oldState.testSlots, (ts) => ts.testId === testId),
      );

      set((state) => {
        ArrayMapUtils.update(state.tests, {
          ids: [testId],
          updater: (t) => ({
            ...t,
            ...setTestModeManipulator({
              test: oldTest,
              mode,
              testSlots: oldTestSlots,
            }),
          }),
        });
      });
      trpcProxyClient.test.setTestMode.mutate({ testId, mode }).catch(
        handleTRPCMutationError(
          { mutationDescription: "edit test mode" },
          () => {
            set((state) => {
              const testSlots = ArrayMapUtils.toArray(
                ArrayMapUtils.filter(
                  state.testSlots,
                  (ts) => ts.testId === testId,
                ),
              );
              const mode = {
                tutor: oldTest.mode.includes("tutor"),
                timed: oldTest.mode.includes("timed"),
              };

              ArrayMapUtils.update(state.tests, {
                ids: [testId],
                updater: (test) => ({
                  ...test,
                  ...setTestModeManipulator({
                    test,
                    mode,
                    testSlots,
                  }),
                }),
              });
            });
          },
        ),
      );
    },
    suspendTest: ({ testId }, opts) => {
      const oldState = get();
      const oldTest = ArrayMapUtils.find(oldState.tests, testId);
      if (!oldTest) return;
      set((state) => {
        ArrayMapUtils.update(state.tests, {
          ids: [testId],
          updater: (test) => ({ ...test, ...suspendTestManipulator({ test }) }),
        });
      });
      if (opts?.local) return;
      trpcProxyClient.test.suspendTest
        .mutate({ testId })
        .then(
          handleTRPCMutationSuccess({
            mutationPastPrinciple: "suspended the test",
          }),
        )
        .catch(
          handleTRPCMutationError(
            { mutationDescription: "suspend the test" },
            () => {
              set((state) => {
                ArrayMapUtils.update(state.tests, {
                  ids: [testId],
                  updater: (test) => ({
                    ...test,
                    suspended: oldTest.suspended,
                    suspendedAt: oldTest.suspendedAt,
                    timeElapsed: oldTest.timeElapsed,
                  }),
                });
              });
            },
          ),
        );
    },
    submitTest: ({ testId }, opts) => {
      const oldState = get();
      const oldTest = ArrayMapUtils.find(oldState.tests, testId);
      const oldTestSlots = ArrayMapUtils.filter(
        oldState.testSlots,
        (ts) => ts.testId === testId,
      );
      const oldQuestionChoices = ArrayMapUtils.filter(
        oldState.questionChoices,
        (qc) =>
          !!ArrayMapUtils.find(oldTestSlots, (ts) => ts.chosenId === qc.id),
      );
      if (!oldTest) return;
      set((state) => {
        ArrayMapUtils.update(state.tests, {
          ids: [testId],
          updater: (test) => ({
            ...test,
            ...submitTestManipulator({ test }),
            score: calculateTestScore({
              testSlots: ArrayMapUtils.toArray(oldTestSlots),
              questionChoices: ArrayMapUtils.toArray(oldQuestionChoices),
            }),
          }),
        });
      });
      if (!opts?.local) {
        trpcProxyClient.test.submitTest
          .mutate({ testId })
          .then(
            handleTRPCMutationSuccess({
              mutationPastPrinciple: "submitted test",
            }),
          )
          .catch(
            handleTRPCMutationError(
              { mutationDescription: "submit test" },
              () => {
                set((state) => {
                  ArrayMapUtils.update(state.tests, {
                    ids: [testId],
                    updater: (test) => ({
                      ...test,
                      ...submitTestManipulator({ test }, { reverse: true }),
                    }),
                  });
                });
              },
            ),
          );
      }
      const testSlots = ArrayMapUtils.filter(
        oldState.testSlots,
        (ts) => ts.testId === testId,
      );
      ArrayMapUtils.forEach(testSlots, (testSlot) => {
        oldState.submitTestSlot(
          {
            testSlotId: testSlot.id,
            chosenChoiceId: testSlot.chosenId,
          },
          {
            local: true,
            preventAutomaticTestSubmission: true,
          },
        );
      });
    },
    resumeTest: ({ testId }) => {
      const oldState = get();
      const oldTest = ArrayMapUtils.find(oldState.tests, testId);
      if (!oldTest) return;
      set((state) => {
        const update = resumeTestManipulator({ test: oldTest });
        ArrayMapUtils.update(state.tests, {
          ids: [testId],
          updater: (test) => ({
            ...test,
            ...update,
          }),
        });
      });
      const selectedSlot = ArrayMapUtils.find(
        oldState.testSlots,
        (s) => s.order === oldTest.selectedSlot && s.testId === oldTest.id,
      );
      selectedSlot && oldState.selectTestSlot({ testSlotId: selectedSlot.id });

      trpcProxyClient.test.resumeTest.mutate({ testId }).catch(
        handleTRPCMutationError({ mutationDescription: "resume test" }, () => {
          const state = get();
          state.suspendTest({ testId }, { local: true });
        }),
      );
    },

    incrementTestElapsedTime: ({ testId }) => {
      const oldState = get();
      const oldTest = ArrayMapUtils.find(oldState.tests, testId);
      if (!oldTest) return;
      if (oldTest.suspended || oldTest.submitted) return;
      set((state) => {
        const currentSlot = ArrayMapUtils.find(
          state.testSlots,
          (s) => s.order === oldTest.selectedSlot && s.testId === oldTest.id,
        );
        if (currentSlot?.submitted) {
          ArrayMapUtils.update(state.tests, {
            ids: [testId],
            updater: (test) => ({
              ...test,
              endsAt: test.endsAt
                ? DateTime.fromJSDate(new Date(test.endsAt))
                    .plus(Duration.fromObject({ seconds: 1 }))
                    .toJSDate()
                : null,
            }),
          });
          return;
        }
        ArrayMapUtils.update(state.tests, {
          ids: [testId],
          updater: (test) => ({
            ...test,
            timeElapsed: test.timeElapsed + 1,
          }),
        });
      });
    },
    resetTests: ({ testIds }) => {
      set((state) => {
        ArrayMapUtils.forEach(
          ArrayMapUtils.filter(state.tests, (t) => testIds.includes(t.id)),
          (test) => {
            ArrayMapUtils.delete(state.tests, test.id);
          },
        );
      });
    },
    createTest: async ({ test, testSlots }) => {
      const state = get();
      const user = state.currentUser;
      if (!user) return undefined;
      const newTest = createTestManipulator({
        test,
        testSlotCount: testSlots.length,
        user,
      });
      const newTestSlots = createTestTestSlotsManipulator({
        testSlots,
        test: newTest,
      });
      const questions = ArrayMapUtils.filter(
        state.questionHeaders,
        (qh) => !!newTestSlots.find((ts) => ts.questionId === qh.id),
      );
      const subjects = ArrayMapUtils.toArray(
        ArrayMapUtils.filter(
          state.subjects,
          (subject) =>
            !!ArrayMapUtils.find(questions, (q) => q.subjectId === subject.id),
        ),
      ).map((s) => s.id);
      const systems = ArrayMapUtils.toArray(
        ArrayMapUtils.filter(
          state.systems,
          (system) =>
            !!ArrayMapUtils.find(questions, (q) => q.systemId === system.id),
        ),
      ).map((s) => s.id);
      const topics = ArrayMapUtils.toArray(
        ArrayMapUtils.filter(
          state.topics,
          (topic) =>
            !!ArrayMapUtils.find(questions, (q) => q.topicId === topic.id),
        ),
      ).map((t) => t.id);

      test.blueprintId && state.useBlueprint({ blueprintId: test.blueprintId });

      state.addTest({
        ...newTest,
        submitted: false,
        timeElapsed: 0,
        submittedAt: null,
        finishedEvaluationAt: null,
        score: null,
        isBeingEvaluated: false,
        suspendedAt: null,
        suspended: false,
        lastUpdatedTimeAt: new Date(),
        selectedSlot: 0,
        slots: newTestSlots.map((ts) => ts.id),
        questions: newTestSlots.map((ts) => ts.questionId),
        subjects,
        systems,
        topics,
      });
      state.addTestSlots(
        newTestSlots.map((ts) => ({
          ...ts,
          crossed: [],
          chosenId: null,
          submittedAt: null,
          timeElapsed: 0,
          submitted: false,
        })),
      );
      const result = new Promise<Pick<TestWithArticles, "id"> | undefined>(
        (resolve, reject) => {
          trpcProxyClient.test.createTest
            .mutate({
              test: {
                ...test,
                id: newTest.id,
                name: newTest.name,
                blueprintId: newTest.blueprintId,
              },
              testSlots: newTestSlots,
            })
            .then(
              handleTRPCMutationSuccess(
                { mutationPastPrinciple: "created test" },
                async (result) => {
                  const previousTestsPageState =
                    usePreviousTestsPageStore.getState();
                  useCreateTestPageStore.getState().reset();
                  resolve(result);
                  //Prefetching the previous tests query to be
                  //in sync
                  const previousTestsQueryInput = {
                    qBankId: result.questionBankId,
                    page: previousTestsPageState.currentPage,
                    testsPerPage: previousTestsPageState.testsPerPage,
                    search: previousTestsPageState.query,
                  };
                  const previousTestsQueryKey = getQueryKey(
                    trpc.test.getPreviousTests,
                    previousTestsQueryInput,
                    "query",
                  );
                  await queryClient.invalidateQueries({
                    queryKey: previousTestsQueryKey,
                  });
                  await queryClient.prefetchQuery({
                    queryKey: previousTestsQueryKey,
                    queryFn: () =>
                      trpcProxyClient.test.getPreviousTests.query(
                        previousTestsQueryInput,
                      ),
                  });
                },
              ),
            )
            .catch(
              handleTRPCMutationError(
                { mutationDescription: "create test" },
                () => {
                  get().deleteTest(
                    { testId: newTest.id, resetQuestions: false },
                    {
                      local: true,
                    },
                  );
                  resolve(undefined);
                },
              ),
            );
        },
      );
      return result;
    },
  };
};

export default createTestSlice;
