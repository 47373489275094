import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleStop } from "@fortawesome/free-solid-svg-icons";
import { useNbmeLayoutStore } from "@/utils/stores/nbmeLayoutStore";
import { useCanSubmit } from "@/utils/stores/testStore";
import useTestPageTestSession from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-session";

export const NbmeEndTestButton = () => {
  const { setDialogVisible, dialogVisible } = useNbmeLayoutStore((state) => ({
    setDialogVisible: state.setEndTestDialogVisible,
    dialogVisible: state.endTestDialogVisible,
  }));
  const { data: test } = useTestPageTestSession();
  const submitted = test?.submitted;
  const [canSubmit] = useCanSubmit();

  return (
    <NbmeIconNavbarButton
      disabled={!canSubmit}
      className="w-full md:w-auto"
      icon={
        <div className="flex flex-row justify-center items-center bg-white rounded-full">
          <FontAwesomeIcon
            icon={faCircleStop}
            className="w-6 h-6 text-red-500"
          />
        </div>
      }
      label={submitted ? "End Review" : "End Block"}
      onClick={() => setDialogVisible(!dialogVisible)}
    />
  );
};
