import { DashboardTable } from "@/components/DashboardTable";
import { useReportOptions } from "../../-hooks";
import { ProgressBar } from "@/components/ProgressBar";
import { percent } from "@/utils/common/percent";
import { TestResultOutput } from "@/api/src/server/routers/statistics/types";

export const PerformanceTestAnalysisPageSystemsTable = ({
  systems
}: {
  systems: TestResultOutput['systems']
}) => {
  const [ options ] = useReportOptions();
  return (
    <DashboardTable
      currentPage={1}
      itemsPerPage={1000000}
      items={systems.sort((a, b) => a.name.localeCompare(b.name))}
      columnContentMapper={(column, content) => {
        if (column.name === "Name") {
          const { name, correct, incorrect, omitted, total } = content;
          return (
            <div className="flex flex-col justify-start items-start gap-3 w-[10rem] md:w-[20rem]">
              <p className="text-start">{name}</p>
              <ProgressBar
                className="bg-white md:bg-gray-200"
                percentages={[
                  options.includes("Correct") ? percent(correct, total) : 0,
                  options.includes("Incorrect") ? percent(incorrect, total) : 0,
                  options.includes("Omitted") ? percent(omitted, total) : 0,
                ]}
                percentageClassNames={[
                  "bg-lime-500",
                  "bg-red-500",
                  "bg-sky-500",
                ]}
              />
            </div>
          );
        }
        return content;
      }}
      columns={{
        collapsible: true,
        columns: [
          {
            name: "Name",
            visible: true,
            content: (item) => ({
              name: item.name,
              total: item.total,
              correct: item.correct,
              incorrect: item.incorrect,
              omitted: item.omitted,
            }),
          },
          {
            name: "Total Q",
            visible: true,
            content: (item) => item.total,
          },
          {
            name: "Correct Q",
            visible: true,
            content: (item) => item.correct,
          },
          {
            name: "Incorrect Q",
            visible: true,
            content: (item) => item.incorrect,
          },
          {
            name: "Omitted Q",
            visible: true,
            content: (item) => item.omitted,
          },
        ],
        collapsedColumns: [
          {
            name: "Name",
            visible: true,
            content: (item) => item.name,
          },
          {
            name: "Total Q",
            visible: true,
            content: (item) => item.total,
          },
          {
            name: "Correct Q",
            visible: true,
            content: (item) => item.correct,
          },
          {
            name: "Incorrect Q",
            visible: true,
            content: (item) => item.incorrect,
          },
          {
            name: "Omitted Q",
            visible: true,
            content: (item) => item.omitted,
          },
        ],
      }}
      collapsedItems={(item) => item.children.sort((a, b) => a.name.localeCompare(b.name))}
    />
  );
};
