import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePause } from "@fortawesome/free-regular-svg-icons";
import { useNbmeLayoutStore } from "@/utils/stores/nbmeLayoutStore";
import useTestPageTestSession from "@/routes/tests/$testId/-test-page-hooks/use-test-page-test-session";

export const NbmeSuspendButton = () => {
  const { setDialogVisible, dialogVisible } = useNbmeLayoutStore((state) => ({
    setDialogVisible: state.setSuspendTestDialogVisible,
    dialogVisible: state.suspendTestDialogVisible,
  }));

  const { data: test } = useTestPageTestSession();
  const submitted = test?.submitted;

  if (submitted) return <></>;
  return (
    <NbmeIconNavbarButton
      className="w-full md:w-auto"
      icon={<FontAwesomeIcon icon={faCirclePause} className="w-6 h-6" />}
      label="Suspend"
      onClick={() => setDialogVisible(!dialogVisible)}
    />
  );
};
