import { NbmeIconNavbarButton } from "./NbmeIconNavbarButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboard } from "@fortawesome/free-regular-svg-icons";
import { faClipboard as faSolidClipboard } from "@fortawesome/free-solid-svg-icons";
import { useNbmeLayoutStore } from "@/utils/stores/nbmeLayoutStore";
import useQuestionQuestionNote from "@/hooks/question-note-hooks/use-question-question-note";
import { useTestPageSelectedTestSlot } from "@/routes/tests/$testId/-test-page-hooks/use-test-page-selected-test-slot";

export const NbmeQuestionNoteButton = () => {
  const toggleNotes = useNbmeLayoutStore((state) => state.setNotesVisible);
  const notesVisible = useNbmeLayoutStore((state) => state.notesVisible);

  const { data: slot, isLoading } = useTestPageSelectedTestSlot();
  const question = slot?.question;
  const questionNote = question?.note;
  const hasNote = !!questionNote;

  return (
    <NbmeIconNavbarButton
      isLoading={isLoading}
      className="w-full md:w-auto"
      icon={
        <FontAwesomeIcon
          icon={hasNote ? faSolidClipboard : faClipboard}
          className="w-8 h-8"
        />
      }
      label="Notes"
      onClick={() => toggleNotes(!notesVisible)}
    />
  );
};
