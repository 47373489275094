import NotesPageNoteCard from "./notes-page-note-card";
import { paginateArray } from "@/utils/common/paginateArray";
import { motion } from "framer-motion";
import { fadeAnimation } from "@/assets/framer";
import { LoadingSpinner } from "@/components/LoadingSpinner";
import { twMerge } from "tailwind-merge";
import useNotesPageNotes from "../-notes-page-hooks/use-notes-page-notes";
import useNotesPagePagination from "../-notes-page-hooks/use-notes-page-pagination";
import useEditQuestionNote from "@/hooks/question-note-hooks/use-edit-question-note";
import useDeleteQuestionNote from "@/hooks/question-note-hooks/use-delete-question-note";

const NotesPageNotesContainer = ({
  qbankEndpoint,
}: {
  qbankEndpoint: string;
}) => {
  const { data: questionNotes, isLoading } = useNotesPageNotes();
  const { mutate: editQuestionNote } = useEditQuestionNote();
  const { mutate: deleteQuestionNote } = useDeleteQuestionNote();
  const { currentPage: currentPageState, notesPerPage: notesPerPageState } =
    useNotesPagePagination();
  const [notesPerPage] = notesPerPageState;
  const [currentPage] = currentPageState;

  const paginatedArray = paginateArray({
    array: questionNotes,
    pageSize: notesPerPage,
    pageNumber: currentPage,
  });

  //In the future get the data already paginated from
  //the database because users will probably have a huge nubmer
  //of notes.

  return (
    <div
      className={twMerge(
        "w-full min-h-full h-full flex flex-col justify-start items-center gap-5",
      )}
    >
      {!isLoading && (
        <p className="w-full text-start text-sm md:text-base text-gray-800 dark:text-neutral-300">
          {questionNotes.length} Total Note
          {questionNotes.length !== 1 ? "s" : ""}
        </p>
      )}
      <motion.div
        {...fadeAnimation}
        className={twMerge(
          "w-full min-h-full h-full flex flex-col justify-start items-center gap-5",
          isLoading && "justify-center",
        )}
        key={`${paginatedArray.length}-${paginatedArray.at(-1)?.id
          }-${currentPage}`}
      >
        {isLoading && <LoadingSpinner key={"loading"} className="w-12 h-12" />}
        {!isLoading &&
          paginatedArray.map((note) => (
            <NotesPageNoteCard
              key={note.id}
              text={note.text}
              questionId={note.questionId}
              onNoteEdited={(text) =>
                editQuestionNote({ questionNoteId: note.id, text })
              }
              qbankEndpoint={qbankEndpoint}
              onNoteDeleted={() =>
                deleteQuestionNote({ questionNoteId: note.id })
              }
            />
          ))}
      </motion.div>
    </div>
  );
};

export default NotesPageNotesContainer;
