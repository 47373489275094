import { QuestionBank } from "@/models";
import { trpc } from "@/utils/trpc";

const useQuestionBankOverallStatistics = (
  questionBank: Pick<QuestionBank, "id">,
) => {
  return trpc.statistics.getQuestionBankOverallStatistics.useQuery(
    {
      qBankId: questionBank.id,
    },
    { enabled: !!questionBank.id },
  );
};
export default useQuestionBankOverallStatistics;
