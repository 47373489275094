import { Tabs } from "@/components/Tabs";
import { usePerformanceReportsPageTabs } from "../-hooks";
import { titlize } from "@/utils/common/titlize";
import { trpc } from "@/utils/trpc";
import usePerformanceReportsPageStore from "../-store";

export const PerformanceReportsPageTabs = ({ questionBankId }: { questionBankId: string }) => {
  const { reportsPerPage, search } = usePerformanceReportsPageStore(state => ({
    reportsPerPage: state.reportsPerPage,
    search: state.query,
  }))

  const { tabs, setCurrentTab, currentTab } = usePerformanceReportsPageTabs();

  trpc.statistics.getQuestionBankPerformanceReports.usePrefetchQuery(
    { qBankId: questionBankId, for: "topics", page: 1, reportsPerPage, search }
  );

  trpc.statistics.getQuestionBankPerformanceReports.usePrefetchQuery(
    { qBankId: questionBankId, for: "subjects", page: 1, reportsPerPage, search }
  );

  trpc.statistics.getQuestionBankPerformanceReports.usePrefetchQuery(
    { qBankId: questionBankId, for: "systems", page: 1, reportsPerPage, search }
  );

  return (
    <Tabs
      variant="dashboard"
      tabs={tabs.map((t) => titlize(t))}
      currentTab={titlize(currentTab)}
      // 'as' here to put the typescript compiler to sleep
      onCurrentTabChange={(t) =>
        setCurrentTab(t.toLowerCase() as "subjects" | "systems" | "topics")
      }
    />
  );
};
