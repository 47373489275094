import { DashboardTable } from "@/components/DashboardTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faChevronRight,
  faMinusCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { twMerge } from "tailwind-merge";
import { useBreakPoint } from "@/utils/hooks";
import { ArrayElement, BreakPoints, TableRow } from "@/types";
import { swap } from "@/utils/common/swap";
import { usePerformanceTestCurrentPage, usePerformanceTestQuestionsPerPage, useReportOptions } from "../../-hooks";
import useTestResult from "../-hooks/use-test-result";
import { Test } from "@/models/test-model";
import { Link } from "@tanstack/react-router";
import { TestResultOutput } from "@/api/src/server/routers/statistics/types";
import { ComponentProps, useMemo, useState } from "react";
import _ from "underscore";
import { Paginator } from "@/components/Paginator";

export const PerformanceTestResultsPageTable = ({
  testId,
}: {
  testId: Test["id"];
}) => {
  const [options] = useReportOptions();
  const breakPoint = useBreakPoint();
  const isScreenSmall = breakPoint < BreakPoints.Medium;
  const { data: result } = useTestResult({ testId });

  let columns: TableRow<ArrayElement<TestResultOutput['questions']>>["columns"] = [
    {
      name: isScreenSmall ? "State" : "",
      visible: true,
      content: (question) => ({
        correct: question.correct === true,
        omitted: question.correct === undefined,
      }),
    },
    {
      name: "ID",
      visible: true,
      content: (question) => question.id,
    },
    {
      name: "Subject",
      visible: true,
      content: (question) => question.subject,
    },
    {
      name: "System",
      visible: true,
      content: (question) => question.system,
    },
    {
      name: "Topic",
      visible: true,
      content: (question) => question.topic,
    },
    {
      name: "% Correct Others",
      visible: true,
      content: (question) => `${question.othersCorrect}%`,
    },
    {
      name: "Time Spent",
      visible: true,
      content: (question) =>
        question.timeSpent > 60
          ? `${(question.timeSpent / 60).toFixed(1)} mins`
          : `${question.timeSpent} sec`,
    },
    {
      name: " ",
      visible: !isScreenSmall,
      content: (question) => question.slotOrder,
    },
  ];

  columns = isScreenSmall
    ? columns.filter((column) => column.name !== " ")
    : columns;
  if (isScreenSmall) swap(0, 1, columns);
  const [page, setPage] = usePerformanceTestCurrentPage();
  const [questionsPerPage, setQuestionsPerPage] = usePerformanceTestQuestionsPerPage();

  const filteredQuestions = useMemo(() => _.sortBy(result?.questions.filter((question) => {
    const isCorrent = question.correct === true;
    const isIncorrect = question.correct === false;
    const isOmitted = question.correct === undefined;
    const isMarked = question.marked;
    const isShown =
      (isCorrent && options.includes("Correct")) ||
      (isIncorrect && options.includes("Incorrect")) ||
      (isOmitted && options.includes("Omitted")) ||
      (isMarked && options.includes("Marked"));

    return isShown;
  }) ?? [], q => q.slotOrder), [result, options]);

  return (
    <div className="flex flex-col justify-start items-center w-full">
      <DashboardTable
        itemsPerPage={questionsPerPage}
        currentPage={page}
        items={filteredQuestions}
        columns={{
          collapsible: false,
          columns: columns,
        }}
        columnContentMapper={(column, content) => {
          if (!column.name) {
            const isOmitted = content.omitted;
            const isCorrect = !isOmitted && content.correct;
            const isIncorrect = !isOmitted && !content.correct;
            return (
              <FontAwesomeIcon
                className={twMerge(
                  isCorrect && "text-lime-500",
                  isIncorrect && "text-red-500",
                  isOmitted && "text-sky-500",
                )}
                icon={isOmitted ? faMinusCircle : isCorrect ? faCheck : faXmark}
              />
            );
          }
          if (column.name === "State") {
            const isOmitted = content.omitted;
            const isCorrect = !isOmitted && content.correct;
            const isIncorrect = !isOmitted && !content.correct;
            return (
              <div className="flex flex-row justify-start items-center gap-1">
                {isScreenSmall && (
                  <p>
                    {isOmitted ? "Omitted" : isCorrect ? "Correct" : "Incorrect"}
                  </p>
                )}
                <FontAwesomeIcon
                  className={twMerge(
                    isCorrect && "text-lime-500",
                    isIncorrect && "text-red-500",
                    isOmitted && "text-sky-500",
                  )}
                  icon={isOmitted ? faMinusCircle : isCorrect ? faCheck : faXmark}
                />
              </div>
            );
          }
          if (column.name === " ") {
            return (
              <Link
                to="/tests/$testId"
                params={{ testId: String(testId) }}
                search={{ slot: content }}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Link>
            );
          }
          return content;
        }}
      />
      <div className="self-end px-10 py-4">
        <Paginator currentPage={page} onCurrentPageChange={setPage} itemsPerPage={questionsPerPage} onItemsPerPageChange={setQuestionsPerPage} numberOfItems={filteredQuestions.length} itemSingularName="question" />
      </div>
    </div>
  );
};
