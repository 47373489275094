import { GetPreviousTestsOutput } from "@/api/src/server/routers";
import {
  QuestionBankOverallStatisticsOutput,
  TestResultOutput,
} from "@/api/src/server/routers/statistics/types";
import useDashboardQuestionBank from "@/routes/qbanks/$qbankEndpoint/dashboard/-dashboard-hooks/use-dashboard-question-bank";
import usePerformanceReportsPageStore from "@/routes/qbanks/$qbankEndpoint/dashboard/_dashboard.performance/reports/-store";
import { usePreviousTestsPageStore } from "@/utils/stores";
import { queryClient, trpc, trpcProxyClient } from "@/utils/trpc";
import { ArrayElement } from "@coursology/types";
import { getQueryKey } from "@trpc/react-query";
import { toast } from "sonner";

const useDeleteTest = () => {
  const { data: questionBank } = useDashboardQuestionBank();
  return trpc.test.deleteTest.useMutation({
    onMutate: ({ testId, resetQuestions }) => {
      const { query, testsPerPage, currentPage } =
        usePreviousTestsPageStore.getState();

      const overallStatsQueryKey = getQueryKey(
        trpc.statistics.getQuestionBankOverallStatistics,
        { qBankId: questionBank?.id ?? "" },
        "query",
      );

      const testResultQueryKey = getQueryKey(
        trpc.statistics.getTestResult,
        { testId },
        "query",
      );

      const previousTestpageQueryKey = getQueryKey(
        trpc.test.getPreviousTests,
        {
          search: query,
          page: currentPage,
          testsPerPage,
          qBankId: questionBank?.id ?? "",
        },
        "query",
      );

      let targetTest:
        | ArrayElement<GetPreviousTestsOutput["tests"]>
        | undefined = undefined;

      queryClient.setQueryData<GetPreviousTestsOutput>(
        previousTestpageQueryKey,
        (previous) => {
          if (!previous) return;
          return {
            ...previous,
            tests: previous.tests.filter((t) => {
              if (t.id !== testId) {
                return true;
              }
              targetTest = t;
              return false;
            }),
          };
        },
      );
      let oldOverallStats: QuestionBankOverallStatisticsOutput | undefined =
        undefined;

      if (resetQuestions) {
        const testResult =
          queryClient.getQueryData<TestResultOutput>(testResultQueryKey);
        queryClient.setQueryData<QuestionBankOverallStatisticsOutput>(
          overallStatsQueryKey,
          (previous) => {
            oldOverallStats = previous;
            if (!previous || !testResult) return previous;
            return {
              ...previous,
              tests: {
                ...previous.tests,
                completed: previous.tests.completed - 1,
                total: previous.tests.total - 1,
              },
              questions: {
                ...previous.questions,
                used: previous.questions.used - testResult.total,
                unused: previous.questions.used + testResult.total,
                correct: previous.questions.correct - testResult.correct,
                incorrect: previous.questions.incorrect - testResult.incorrect,
                omitted: previous.questions.omitted - testResult.omitted,
              },
            };
          },
        );
      }

      //Function to rollback change
      return {
        previousTestpageQueryKey,
        overallStatsQueryKey,
        rollback: () => {
          if (!targetTest) return;
          queryClient.setQueryData<GetPreviousTestsOutput>(
            previousTestpageQueryKey,
            (previous) => {
              if (!previous || !targetTest) return;
              return { ...previous, tests: [...previous.tests, targetTest] };
            },
          );
          if (resetQuestions && oldOverallStats) {
            queryClient.setQueryData<QuestionBankOverallStatisticsOutput>(
              overallStatsQueryKey,
              oldOverallStats,
            );
          }
        },
      };
    },
    onSuccess: (
      data,
      variables,
      { overallStatsQueryKey, previousTestpageQueryKey },
    ) => {
      if (variables.resetQuestions) {
        queryClient
          .invalidateQueries({ queryKey: overallStatsQueryKey })
          .then(() => {
            queryClient.prefetchQuery({
              queryKey: overallStatsQueryKey,
              queryFn: () =>
                trpcProxyClient.statistics.getQuestionBankOverallStatistics.query(
                  { qBankId: questionBank?.id ?? "" },
                ),
            });
          });
        const performanceReportsPageState =
          usePerformanceReportsPageStore.getState();
        (["subjects", "systems", "topics"] as const).forEach((articleType) => {
          const queryInputs = {
            qBankId: questionBank?.id ?? "",
            search: performanceReportsPageState.query,
            for: articleType,
            page: performanceReportsPageState.currentPage,
            reportsPerPage: performanceReportsPageState.reportsPerPage,
          };
          const performanceReportsQueryKey = getQueryKey(
            trpc.statistics.getQuestionBankPerformanceReports,
            queryInputs,
            "query",
          );
          queryClient
            .invalidateQueries({ queryKey: performanceReportsQueryKey })
            .then(() => {
              queryClient.prefetchQuery({
                queryKey: performanceReportsQueryKey,
                queryFn: () =>
                  trpcProxyClient.statistics.getQuestionBankPerformanceReports.query(
                    queryInputs,
                  ),
              });
            });
        });
      }

      queryClient.invalidateQueries({ queryKey: previousTestpageQueryKey });
    },
    onError: (err, _, ctx) => {
      toast.error(`Could not delete test..., an error ocurred: ${err.message}`);
      ctx?.rollback?.();
    },
  });
};
export default useDeleteTest;
