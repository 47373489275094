import { Hint } from "@/api/types";
import { trpc } from "@/utils/trpc";

export const useHintMedia = (
  hint: Pick<Hint, "imageId" | "videoId">,
  { enabled }: { enabled: boolean },
) => {
  const image = trpc.image.getById.useQuery(
    {
      id: hint.imageId!,
      size: 3,
    },
    {
      enabled: !!hint.imageId && enabled,
    },
  );

  const video = trpc.video.getById.useQuery(
    {
      id: hint.videoId!,
    },
    {
      enabled: !!hint.videoId && enabled,
    },
  );

  return { image, video };
};
