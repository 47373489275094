import { Notebook } from "./common/Notebook";
import {
  useNbmeNotebookVisible,
  useNbmeSplitView,
} from "@/utils/stores/nbmeLayoutStore";
import { MobileDrawer } from "./common/Notebook/components/Sidebar/components/Layout/components";
import { useNotebookUiVariant } from "@/hooks/notebook";
import React, { PropsWithChildren } from "react";
import NbmeSidePanel from "./nbme-side-panel";
import { Setter } from "@/types";
import { useTestPageSelectedTestSlot } from "@/routes/tests/$testId/-test-page-hooks/use-test-page-selected-test-slot";

const SidePanel = ({
  children,
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: Setter<boolean>;
} & PropsWithChildren) => {
  const [splitView] = useNbmeSplitView();
  const { data: selectedSlot } = useTestPageSelectedTestSlot();
  return (
    <NbmeSidePanel.Root
      draggable={false}
      emergeFrom={splitView && selectedSlot?.submitted ? "left" : "right"}
      open={open}
      onOpenChanged={onOpenChange}
      closeOnClickOutside={false}
      setCloseOnClickOutside={() => false}
    >
      <NbmeSidePanel.Body className="max-w-[50vw] h-screen w-[50vw] p-0 border-l-gray-300 overflow-hidden max-h-screen dark:border-l-neutral-600 dark:bg-neutral-900">
        <NbmeSidePanel.Header className="absolute top-3 bg-transparent warm:bg-transparent dark:bg-transparent z-[5] dark:bg-neutral-950 border-b-transparent warm:border-b-transparent dark:border-b-transparent border-b-gray-300 dark:border-b-neutral-600">
          <NbmeSidePanel.CloseButton className="dark:text-neutral-100" />
        </NbmeSidePanel.Header>
        <div className="max-h-screen overflow-hidden">{children}</div>
      </NbmeSidePanel.Body>
    </NbmeSidePanel.Root>
  );
};

export const NbmeNotebookWindow = React.memo(() => {
  const [isVisible, setIsVisible] = useNbmeNotebookVisible();
  const [variant] = useNotebookUiVariant();
  const onDesktop = variant === "desktop";
  const Parent = onDesktop ? SidePanel : MobileDrawer;

  return (
    <Parent open={isVisible} onOpenChange={setIsVisible}>
      <Notebook />
    </Parent>
  );
});
