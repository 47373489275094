import { Test } from "@/models/test-model";
import { trpc } from "@/utils/trpc";

const useTestResult = ({ testId }: { testId: Test["id"] }) => {
  return trpc.statistics.getTestResult.useQuery(
    { testId },
    { enabled: !!testId },
  );
};

export default useTestResult;
