import { scaleUnitInRem } from "@/utils/common/scaleUnitInRem";
import { useTestStore } from "@/utils/stores/testStore";
import { trpc } from "@/utils/trpc";
import { Interweave } from "interweave";
import { useEffect, useMemo, useRef, useState } from "react";
import { Separator } from "../Separator";
import {
  imageFilter,
  linkFilter,
  listFilter,
  paragraphFilter,
  tableFilter,
  uWorldImageFilter,
} from "@/utils/interweave/filters";
import {
  useNbmeAlignment,
  useNbmeFontSizeFactor,
} from "@/utils/stores/nbmeLayoutStore";
import { useBreakPoint } from "@/utils/hooks/useBreakpoints";
import { BreakPoints, FontSizes, LineHeights } from "@/types";
import { analyzeSelection } from "@/utils/highlights/analyzeSelection";
import { Highlight } from "@/models";
import {
  breaklineTransformer,
  highlightTransformer,
  imageTransformer,
} from "@/utils/interweave/transformers";
import { useHighlightMarker } from "./hooks";
import { cn } from "@/lib/utils";
import combineInterweaveTransformers from "@/lib/utils/combine-interweave-transformers";
import useCreateHighlight from "@/hooks/highlight-hooks/use-create-highlight";
import { hintTransformer } from "@/utils/interweave/transformers/hintTransformer";
import { NbmeExplanationAiSummary } from "./NbmeExplanationAiSummary";
import { NbmeExplanationGenerateAiSummaryButton } from "./NbmeExplanationGenerateSummaryButton";
import { StepAsideInOut } from "@/ncomponents/utilities/animations/step-aside-in-out";
import { useMeasure } from "@/hooks/common/use-measure";
import { AnimatePresence } from "framer-motion";
import { BlurInOut } from "@/ncomponents/utilities/animations/blur-in-out";
import { useParams } from "@tanstack/react-router";
import useTestSlot from "@/hooks/test-slot-hooks/use-testslot";
import useTestSession from "@/hooks/test-hooks/use-test-session";

export const NbmeExplanation = () => {
  const { testId } = useParams({ from: "/tests/$testId" });
  const { data: testSession } = useTestSession({ testId: Number(testId) });
  const { data: testSlot } = useTestSlot({ testId: testSession?.id, slot: testSession?.selectedSlot });
  const question = testSlot?.question;
  const explanation = testSlot?.question.explanation;
  const subject = testSlot?.question.subject;
  const system = testSlot?.question.system;
  const topic = testSlot?.question.topic;
  const { fontSizeFactor } = useNbmeFontSizeFactor();
  const breakPoint = useBreakPoint();

  const questionExplanationRef = useRef<HTMLDivElement>(null);
  const highlightedHtml = useHighlightMarker(
    question?.explanation.id ?? "",
    explanation?.html ?? "",
    question?.source ?? '',
  );

  const [alignment] = useNbmeAlignment();
  const memoizedParagraphFilter = useMemo(
    () => paragraphFilter(fontSizeFactor, alignment),
    [fontSizeFactor, alignment],
  );
  const memoizedTableFilter = useMemo(
    () => tableFilter(breakPoint, fontSizeFactor),
    [breakPoint, fontSizeFactor],
  );
  const memoizedCombinedTransformer = useMemo(
    () =>
      combineInterweaveTransformers(
        highlightTransformer,
        imageTransformer(),
        hintTransformer({ questionSource: question?.source ?? "" }),
      ),
    [
      highlightTransformer,
      imageTransformer,
      hintTransformer,
      question?.source,
    ],
  );
  const memoizedInterweaveFilters = useMemo(
    () => [
      listFilter,
      linkFilter,
      memoizedParagraphFilter,
      memoizedTableFilter,
    ],
    [listFilter, linkFilter, memoizedParagraphFilter, memoizedTableFilter],
  );

  const [summaryRef, { height: summaryHeight }] = useMeasure();

  const memoizedBlockList = useMemo(() => ["br"], []);

  const selectedMarker = useTestStore((state) => state.selectedHighlightMarker);
  const createHighlight = useCreateHighlight();
  const [requestedSummary, setRequestedSummary] = useState<boolean>(false);
  useEffect(() => {
    setRequestedSummary(false);
  }, [testSlot?.id])
  const { data: summary, isLoading: isSummaryLoading } = trpc.ai.generateQuestionExplanationSummary.useQuery({ testSlotId: testSlot?.id ?? '' }, { enabled: !!testSlot?.id && requestedSummary, staleTime: Infinity });
  const isScreenLarge = breakPoint > BreakPoints.Medium;
  const isScreenMedium = breakPoint > BreakPoints.Small;

  return (
    <div className="flex flex-col justify-start items-center w-full gap-4 lg:p-3 p-4">
      <div className="flex flex-row justify-between items-end w-full">
        <p className="font-medium border-t border-r border-l border-gray-300 p-3 rounded-t-md">
          Explanation:
        </p>
        <div className="flex-grow w-full h-full border-b border-gray-300 self-end"></div>
        <div className="relative flex flex-row justify-end items-center border-b border-gray-300 w-full pb-2">
          <AnimatePresence>
            {!summary &&
              <BlurInOut className="relative" state="visible">
                <NbmeExplanationGenerateAiSummaryButton onClick={() => { setRequestedSummary(true) }}
                  isLoading={isSummaryLoading}
                  isDisabled={!!summary}
                />
              </BlurInOut>
            }
          </AnimatePresence>
        </div>
      </div>
      <div className="relative w-full">
        <AnimatePresence>
          {summary &&
            <NbmeExplanationAiSummary key={explanation?.id} ref={summaryRef} questionSource={question?.source ?? ''}
              alignment={alignment}
              breakPoint={breakPoint}
              fontSizeFactor={fontSizeFactor} >
              {summary}
            </NbmeExplanationAiSummary>
          }
        </AnimatePresence>
      </div>
      <StepAsideInOut by={summaryHeight ?? 0} stepAside={!!summary}>
        <div
          className={cn(
            "flex flex-col justify-start items-center lg:items-start gap-4",
            alignment === "center" && "lg:items-center",
            alignment === "right" && "lg:items-end",
          )}
          style={{
            fontSize: scaleUnitInRem(FontSizes.Base, fontSizeFactor)
              .resultInRemAsCss,
            lineHeight: scaleUnitInRem(LineHeights.Base, fontSizeFactor)
              .resultInRemAsCss,
          }}
        >
          {explanation?.html ? (
            <div
              id="question-explanation"
              key={`question-${question?.id}-explanation`}
              ref={questionExplanationRef}
              onMouseUp={handleHighlight}
              onTouchEnd={handleHighlight}
            >
              <Interweave
                className=""
                blockList={memoizedBlockList}
                transform={memoizedCombinedTransformer}
                filters={memoizedInterweaveFilters}
                content={highlightedHtml}
              />
            </div>
          ) : (
            <>
              {/*<p className="whitespace-pre-line">{explanation?.text}</p>*/}
              {/*explanation?.objective && (
                <div className="flex flex-col justify-start items-start gap-1">
                  <h2 className="font-semibold">Educational objective:</h2>
                  <p>{explanation?.objective}</p>
                </div>
              )*/}
              {/*explanation?.references &&
                (explanation?.references as Array<any>).length > 0 && (
                  <div className="flex flex-col justify-start items-start gap-1">
                    <h2 className="font-semibold">References:</h2>
                    <ul className="list-disc inset-10 underline text-nbme-primary-500 ml-8">
                      {(explanation?.references as Array<any>).map(
                        (exp, index) => {
                          const typedExp = exp as { link: string; text: string };
                          return (
                            <li key={index}>
                              <a href={typedExp.link} target="_blank">
                                {typedExp.text}
                              </a>
                            </li>
                          );
                        },
                      )}
                    </ul>
                  </div>
                )*/}
            </>
          )}
          <Separator orientation="horizontal" className="bg-gray-300" />
          <div className="flex flex-wrap md:flex-nowrap flex-row justify-between md:justify-start items-center gap-8">
            <div className="flex flex-col justify-center items-start">
              <p
                className={"font-medium"}
                style={{
                  fontSize: scaleUnitInRem(
                    isScreenMedium ? FontSizes.Base : FontSizes.Small,
                    fontSizeFactor,
                  ).resultInRemAsCss,
                }}
              >
                {subject}
              </p>
              <p
                className="font-light text-gray-500"
                style={{
                  fontSize: scaleUnitInRem(
                    isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                    fontSizeFactor,
                  ).resultInRemAsCss,
                }}
              >
                Subject
              </p>
            </div>
            <div className="flex flex-col justify-center items-start">
              <p
                className={"font-medium"}
                style={{
                  fontSize: scaleUnitInRem(
                    isScreenMedium ? FontSizes.Base : FontSizes.Small,
                    fontSizeFactor,
                  ).resultInRemAsCss,
                }}
              >
                {system}
              </p>
              <p
                className="font-light text-gray-500"
                style={{
                  fontSize: scaleUnitInRem(
                    isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                    fontSizeFactor,
                  ).resultInRemAsCss,
                }}
              >
                System
              </p>
            </div>
            <div className="flex flex-col justify-center items-start">
              <p
                className={"font-medium"}
                style={{
                  fontSize: scaleUnitInRem(
                    isScreenMedium ? FontSizes.Base : FontSizes.Small,
                    fontSizeFactor,
                  ).resultInRemAsCss,
                }}
              >
                {topic}
              </p>
              <p
                className="font-light text-gray-500"
                style={{
                  fontSize: scaleUnitInRem(
                    isScreenMedium ? FontSizes.Small : FontSizes.ExtraSmall,
                    fontSizeFactor,
                  ).resultInRemAsCss,
                }}
              >
                Topic
              </p>
            </div>
          </div>
        </div>
      </StepAsideInOut>
    </div>
  );
  function handleHighlight() {
    const windowSelection = window.getSelection();
    const analysis = analyzeSelection(windowSelection);
    if (!analysis) return;
    if (!analysis.selectionText) return;
    if (!selectedMarker) return;
    if (analysis.selectionText === " " || analysis.selectionText.length === 0) {
      return;
    }

    createHighlight({
      highlight: {
        text: analysis.selectionText!,
        ocurrence: analysis.ocurrence,
        questionId: null,
        questionExplanationId: question?.explanation.id ?? null,
        start: analysis.startIndex,
        end: analysis.endIndex,
        markerId: selectedMarker.id,
      },
    });
    windowSelection?.removeAllRanges();
  }
};
