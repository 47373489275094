import useHighlightMarkers from "@/hooks/highlightmarker-hooks/use-highlightmarkers";
import ArrayMapUtils from "@/types/common/ArrayConvertableMap";
import { alphabetToNumber } from "@/utils/common/alphabetToNumber";
import { useNbmeLayoutStore, useTestStore } from "@/utils/stores";
import { useCallback, useEffect } from "react";
import {
  useTestPageSwitchToNextSlot,
  useTestPageSwitchToPreviousSlot,
} from "@/routes/tests/$testId/-test-page-hooks/use-test-page-switch-selected-slot";
import useTestPageChooseTestSlotQuestionChoice from "@/routes/tests/$testId/-test-page-hooks/use-test-page-choose-testslot-questionchoice";
import useTestPageMarkSelectedTestSlotQuestion from "@/routes/tests/$testId/-test-page-hooks/use-test-page-mark-test-selectedslot-question";
import useTestPageSubmitTestSlot from "@/routes/tests/$testId/-test-page-hooks/use-test-page-submit-test-slot";

const useNbmeKeyboardShortcuts = () => {
  const nextSlot = useTestPageSwitchToNextSlot();
  const previousSlot = useTestPageSwitchToPreviousSlot();
  const chooseChoice = useTestPageChooseTestSlotQuestionChoice();
  const {
    shortcutsEnabled,
    notesVisible,
    toggleNotes,
    calcVisible,
    toggleCalc,
    feedbackVisible,
    toggleFeedback,
    labVisible,
    setSplitView,
    toggleLab,
    sidebarVisible,
    setSidebarVisible,
    splitView,
  } = useNbmeLayoutStore((state) => ({
    fontSizeFactor: state.settings.fontSizeFactor,
    shortcutsEnabled: state.shortcutsEnabled,
    sidebarVisible: state.sidebarVisible,
    setSidebarVisible: state.setSidebarVisible,
    notesVisible: state.notesVisible,
    toggleNotes: state.setNotesVisible,
    calcVisible: state.calcVisible,
    toggleCalc: state.setCalcVisible,
    toggleFeedback: state.setFeedbackVisible,
    feedbackVisible: state.feedbackVisible,
    labVisible: state.labVisbile,
    toggleLab: state.setLabVisible,
    setSplitView: state.setSplitView,
    splitView: state.settings.splitView,
  }));
  const { setSelectedMarker } = useTestStore((state) => ({
    setSelectedMarker: state.setSelectedHighlightMarker,
  }));
  const toggleMarkCurrent = useTestPageMarkSelectedTestSlotQuestion();

  const { data: highlightMarkers } = useHighlightMarkers();
  const submit = useTestPageSubmitTestSlot();

  const handleShortcuts = useCallback(
    (e: KeyboardEvent) => {
      if (e.ctrlKey) return;
      if (e.code === "ArrowLeft") {
        e.preventDefault();
        previousSlot();
      }
      if (e.code === "ArrowRight") {
        e.preventDefault();
        nextSlot();
      }

      if (/Key[A-Z]/.test(e.code) && !e.altKey) {
        //if (!selected) return;
        //if (selected.submitted) return;
        //const choice = questionChoices?.find(
        //  (c) => c.order === alphabetToNumber(e.key),
        //);
        //if (!choice) return;

        e.preventDefault();
        //chooseChoice({
        //  questionChoiceId: choice.id,
        //});
      }
      switch (e.code) {
        case "Backquote":
          e.preventDefault();
          setSelectedMarker(undefined);
          return;
        case "Digit1":
          e.preventDefault();
          setSelectedMarker(
            ArrayMapUtils.find(highlightMarkers, (hm) => hm.color === "#ff0"),
          );
          return;
        case "Digit2":
          e.preventDefault();
          setSelectedMarker(
            ArrayMapUtils.find(highlightMarkers, (hm) => hm.color === "#0f0"),
          );
          return;
        case "Digit3":
          e.preventDefault();

          setSelectedMarker(
            ArrayMapUtils.find(
              highlightMarkers,
              (hm) => hm.name.toLowerCase() === "cyan",
            ),
          );
          return;
        case "Digit4":
          e.preventDefault();
          setSelectedMarker(
            ArrayMapUtils.find(highlightMarkers, (hm) => hm.color === "#f00"),
          );
          return;
      }

      if (!e.altKey) return;
      switch (e.code) {
        case "KeyM":
          e.preventDefault();
          toggleMarkCurrent();
          break;
        case "KeyP":
          e.preventDefault();
          previousSlot();
          break;
        case "KeyN":
          e.preventDefault();
          nextSlot();
          break;
        case "KeyO":
          e.preventDefault();
          toggleNotes(!notesVisible);
          break;
        case "KeyU":
          e.preventDefault();
          toggleCalc(!calcVisible);
          break;
        case "KeyF":
          e.preventDefault();
          toggleFeedback(!feedbackVisible);
          break;
        case "KeyL":
          e.preventDefault();
          toggleLab(!labVisible);
          break;
        case "KeyA":
          e.preventDefault();
          setSidebarVisible(!sidebarVisible);
          break;
        case "KeyS":
          e.preventDefault();
          console.log(!splitView);
          setSplitView(!splitView);
          break;
        case "Enter":
          e.preventDefault();
          submit();
          return;
      }
    },
    [
      labVisible,
      feedbackVisible,
      calcVisible,
      notesVisible,
      splitView,
      toggleNotes,
      toggleCalc,
      nextSlot,
      previousSlot,
      highlightMarkers,
      sidebarVisible,
      setSidebarVisible,
    ],
  );

  useEffect(() => {
    document.addEventListener("keydown", handleShortcuts);
    if (!shortcutsEnabled) {
      return document.removeEventListener("keydown", handleShortcuts);
    }

    return () => document.removeEventListener("keydown", handleShortcuts);
  }, [shortcutsEnabled, handleShortcuts]);
};

export default useNbmeKeyboardShortcuts;
