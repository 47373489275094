import { ArrayElement, Setter, TableRow } from "@/types";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { ItemPerformanceReport, ReportOptions } from "../-types";
import { createJSONStorage, persist } from "zustand/middleware";

export interface PerformanceReportsPageState {
  row: TableRow<ItemPerformanceReport>;
  tabs: ("subjects" | "systems" | "topics")[];
  currentTab: ArrayElement<this["tabs"]>;
  query: string;
  reportOptions: ReportOptions;
  reportsPerPage: 5 | 10 | 15 | 25 | 50 | 100;
  currentPage: number;
}

interface PerformanceReportsPageActions {
  setCurrentTab: Setter<PerformanceReportsPageState["currentTab"]>;
  setQuery: Setter<PerformanceReportsPageState["query"]>;
  setReportOptions: Setter<PerformanceReportsPageState["reportOptions"]>;
  setReportsPerPage: Setter<PerformanceReportsPageState["reportsPerPage"]>;
  setCurrentPage: Setter<PerformanceReportsPageState["currentPage"]>;
}

type PerformanceReportsPageStore = PerformanceReportsPageState &
  PerformanceReportsPageActions;

export const usePerformanceReportsPageStore = create(
  persist(
    immer<PerformanceReportsPageStore>((set) => {
      return {
        row: {
          collapsible: true,
          columns: [
            {
              name: "Name",
              visible: true,
              content: (report) => {
                return report;
              },
            },
            {
              name: "Usage",
              visible: true,
              content: (report) => {
                const { total, used } = report;
                return `${used}/${total}`;
              },
            },
            {
              name: "Correct",
              visible: true,
              content: (report) => {
                const { correct, used } = report;
                const percentage = used ? (correct / used) * 100 : 0;
                return `${correct} (${percentage.toFixed(1)}%)`;
              },
            },
            {
              name: "Incorrect",
              visible: true,
              content: (report) => {
                const { incorrect, used } = report;
                const percentage = used ? (incorrect / used) * 100 : 0;
                return `${incorrect} (${percentage.toFixed(1)}%)`;
              },
            },
            {
              name: "Omitted",
              visible: true,
              content: (report) => {
                const { omitted, used } = report;
                const percentage = used ? (omitted / used) * 100 : 0;
                return `${omitted} (${percentage.toFixed(1)}%)`;
              },
            },
          ],
          collapsedColumns: [
            {
              name: "Name",
              visible: true,
              content: (report) => {
                return report;
              },
            },
            {
              name: "Usage",
              visible: true,
              content: (report) => {
                const { total, used } = report;
                return `${used}/${total}`;
              },
            },
            {
              name: "Correct",
              visible: true,
              content: (report) => {
                const { correct, used } = report;
                const percentage = used ? (correct / used) * 100 : 0;
                return `${correct} (${percentage.toFixed(1)}%)`;
              },
            },
            {
              name: "Incorrect",
              visible: true,
              content: (report) => {
                const { incorrect, used } = report;
                const percentage = used ? (incorrect / used) * 100 : 0;
                return `${incorrect} (${percentage.toFixed(1)}%)`;
              },
            },
            {
              name: "Omitted",
              visible: true,
              content: (report) => {
                const { omitted, used } = report;
                const percentage = used ? (omitted / used) * 100 : 0;
                return `${omitted} (${percentage.toFixed(1)}%)`;
              },
            },
          ],
        },
        currentPage: 1,
        setCurrentPage: (newCurrentPage) =>
          set((state) => {
            state.currentPage = newCurrentPage;
          }),
        reportsPerPage: 10,
        setReportsPerPage: (newReportsPerPage) =>
          set((state) => {
            state.reportsPerPage = newReportsPerPage;
          }),
        tabs: ["subjects", "systems", "topics"],
        currentTab: "subjects",
        setCurrentTab: (newCurrentTab) => {
          set((state) => {
            state.currentTab = newCurrentTab;
          });
        },
        query: "",
        setQuery: (newQuery) =>
          set((state) => {
            state.query = newQuery;
          }),

        reportOptions: {
          type: "Score",
          options: ["Correct", "Incorrect", "Omitted"],
        },
        setReportOptions: (newShownStat) =>
          set((state) => {
            state.reportOptions = newShownStat;
          }),
      };
    }),
    {
      name: "performance-reports-page",
      storage: createJSONStorage(() => localStorage),
      partialize: (state) => ({
        reportOptions: state.reportOptions,
        currentTab: state.currentTab,
        query: state.query,
      }),
    },
  ),
);
