import { trpc } from "@/utils/trpc"
import { faChartLine, faListCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "@tanstack/react-router"

export const TestResultsAnalysisLink = ({ to, testId, qBankEndpoint }: { to: "analysis" | "results", qBankEndpoint: string, testId: number }) => {
  trpc.statistics.getTestResult.usePrefetchQuery({ testId })
  return <Link
    to={`/qbanks/${qBankEndpoint}/dashboard/performance/test/${testId}/${to}`}
  >
    <FontAwesomeIcon icon={to === 'results' ? faListCheck : faChartLine} />
  </Link>

}
