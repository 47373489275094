import { GetPreviousTestsInput } from "@/api/src/server/routers";
import { trpc } from "@/utils/trpc";

const usePreviousTests = (input: GetPreviousTestsInput) => {
  trpc.test.getPreviousTests.usePrefetchQuery({
    ...input,
    page: input.page + 1,
  });
  trpc.test.getPreviousTests.usePrefetchQuery({
    ...input,
    page: input.page + 2,
  });
  return trpc.test.getPreviousTests.useQuery(input, {
    enabled: !!input.qBankId,
    placeholderData: (previous) => previous,
  });
};

export default usePreviousTests;
